import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Component/navbar.jsx";
import Footer from "../Component/footer.jsx";
//import { API_URL } from "../apiConfig";
import { API_URL, DEFAULT_CURRENCY, CURRENCY_CONVERSION } from "../apiConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Crown from "../image/crown.png";
import "./Pricing.css";
import axios from "axios";
import { toast } from "react-toastify";
import R from '../image/favicons/R.svg';
import {Helmet} from "react-helmet";


const Pricing = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [selectedProPlan, setSelectedProPlan] = useState(null);
  const [proPlans, setProPlans] = useState([]);
  const [dnd, setDnd] = useState([]);
  const [selectePlan, setSelectedPlan] = useState("");
  const [userActivePlan, setUserActivePlan] = useState(false)

  const fetchPricingData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/plan/plans`, {
        withCredentials: true,
      });

      if (!response?.data?.success) {
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      }

      const data = response?.data?.data;
      console.log("plans data : ", data);

      setPlans(data?.plans.sort((a, b) => a.plan_price - b.plan_price));

      // Filter the plans based on is_subscribed field
      const dndPlans = data.plans.filter((plan) => plan.is_subscribed === true);
      setDnd(dndPlans);

      const filteredPlans = data.plans.filter(
        (plan) =>
          plan.is_subscribed === false || plan.is_subscribed === undefined
      );

      const proPlansData = filteredPlans.filter(
        (plan) => plan.plan_type === "Pro"
      );
      setProPlans(proPlansData);
      
      const initialProPlan = proPlansData[0];
      setSelectedPlan(initialProPlan.plan_name)
      setSelectedProPlan(initialProPlan);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchPricingData();

  }, []);

  const handleProPlanClick = (planName,activePlan) => {
    setSelectedPlan(planName.toLowerCase())
    setUserActivePlan(activePlan)
    const selectedPlan = proPlans.find(
      (plan) => plan.plan_name.toLowerCase() === planName.toLowerCase()
    );
    
    setSelectedProPlan(selectedPlan);
  };

  const handleCheckout = (planId) => {
    if(userActivePlan){
      toast.error("You've already purchased this plan", {
        autoClose: 2500,
      });
      return;
    }
    if (dnd?.length === 0) {
      navigate(`/checkout?plan_id=${planId}`); // Navigate to checkout page with plan_id
    }
  };

  // const getDiscountedPrice = (plan) => {
  //   return plan.plan_price - plan.plan_sale;
  // };

  // const getDiscountedPriceMon = (plan) => {
  //   const out_price = plan.plan_price;
  //   console.log("out_price", out_price);
  //   console.log("plan", plan);
  //   if (plan?.plan_subtext === "monthly plan") {
  //     return `${out_price}/month`;
  //   }
  //   // else if(plan.duration_sufix == "weekly"){
  //   //   return `${Math.ceil((out_price/plan.plan_duration)* 100) / 100}/day`
  //   // }
  //   else if (plan?.plan_subtext === "quarterly plan") {
  //     console.log(
  //       `${
  //         Math.ceil(
  //           (out_price / (plan.plan_duration * (12 / plan.intervals))) * 100
  //         ) / 100
  //       }/months`
  //     );
  //     return `${
  //       Math.ceil(
  //         (out_price / (plan.plan_duration * (12 / plan.intervals))) * 100
  //       ) / 100
  //     }/month`;
  //   } else if (plan?.plan_subtext === "yearly plan") {
  //     console.log(
  //       `"yearly price : ${
  //         Math.ceil(
  //           (out_price / (plan.plan_duration * (12 / plan.intervals))) * 100
  //         ) / 100
  //       }/month`
  //     );
  //     return `${
  //       Math.ceil(
  //         (out_price / (plan.plan_duration * (12 / plan.intervals))) * 100
  //       ) / 100
  //     }/month`;
  //   }
  // };

  function calculateMonthlyPayment(
    actualPrice,
    salePrice,
    numberOfMonths,
    planName
  ) {
    if (planName === "PRO YEARLY") {
      numberOfMonths = 12;
    }
    const monthlyPayment = salePrice / numberOfMonths;
    return `${(monthlyPayment).toFixed(2)}/month`;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Pricing - ResumeBeen | Affordable Plans for Professional Resume Building</title>
            <link rel="canonical" href="https://resumebeen.com/pricing" />
            <meta property="og:site_name" content="ResumeBeen"></meta>
            <meta property="og:url" content="https://resumebeen.com/pricing"></meta>
            <meta property="og:locale" content="en_EN"></meta>

            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@resumebeen"></meta>
            <meta name="twitter:creator" content="@resumebeen"></meta>

            <meta property="og:type" content="website"></meta>

            <meta name="title" content="Pricing - ResumeBeen | Affordable Plans for Professional Resume Building"></meta>
            <meta property="og:title" content="Pricing - ResumeBeen | Affordable Plans for Professional Resume Building"></meta>


            <meta name="description" content="Explore ResumeBeen's affordable pricing plans designed to fit your career needs. Unlock premium features, advanced customization, and AI-driven tools to create standout resumes and cover letters that help you land your dream job."></meta>
            <meta property="og:description" content="Explore ResumeBeen's affordable pricing plans designed to fit your career needs. Unlock premium features, advanced customization, and AI-driven tools to create standout resumes and cover letters that help you land your dream job."></meta>

            <meta name="keywords" content="ResumeBeen pricing, resume builder cost, subscription plans, resume builder pricing, premium features, affordable resume builder, Matching Mode Analysis, resume customization pricing, professional resume tools, resume builder plans"></meta>
            <meta property="og:keywords" content="ResumeBeen pricing, resume builder cost, subscription plans, resume builder pricing, premium features, affordable resume builder, Matching Mode Analysis, resume customization pricing, professional resume tools, resume builder plans"></meta>

        </Helmet>      
      </>


      <Navbar />
      <main className="flex items-center justify-center h-auto my-20">
        <div className="flex flex-col justify-center items-center h-full w-full lg:min-w-[900px] px-4">
          <span className="big-circle"></span>
          <div className="my-2 font-bold text-7xl">Choose your plan.</div>
          <div className="my-2 text-base">
            Unlock more career possibilities with Hirebeen Premium.
          </div>

          <div className="flex flex-col items-center justify-center w-full mt-6 md:flex-row">
            {plans.map((plan) =>
              plan.plan_type === "Basic" ? (
                <div
                  key={plan.plan_name}
                  className="min-w-[300px] min-h-[400px] p-3 border-1 my-4 md:mb-0 md:mx-4 rounded-2xl overflow-x-auto overflow-y-auto shadow-2xl bg-[rgb(250,250,251)] hover:bg-[rgba(232,234,246,1)] hover:border-orange-400 transform hover:scale-105 transition-transform"
                >
                  <div className="flex flex-col justify-between items-start h-1/3 px-4 pt-4 pb-0 text-[#0d0d0d]">
                    <h3 className="text-2xl font-light tracking-widest uppercase">
                      {plan.plan_name}
                    </h3>
                    <div className="text-3xl font-bold">
                      {DEFAULT_CURRENCY} {Math.round((plan.plan_price - plan.plan_sale) / CURRENCY_CONVERSION)}{}
                    </div>
                    <div className="text-xs font-light">
                      Valid for {plan.plan_duration} {plan.duration_sufix}
                    </div>
                    <hr className="w-11/12 mt-2 border-t-[rgba(245,245,245,0.7)]" />
                  </div>
                  <div className="flex flex-col min-h-[300px] justify-between py-4">
                    <ul className="px-8 space-y-2 text-gray-800 list-none">
                      {plan.plan_description.map((desc, index) => (
                        <li key={index} className="text-base">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-[#1d6362] mr-4"
                          />
                          {desc}
                        </li>
                      ))}
                    </ul>
                    <button className="block w-[160px] py-2 mx-auto mt-8 text-lg bottom-2 font-semibold border-2 uppercase rounded-full transition-colors">
                      Active
                    </button>
                  </div>
                </div>
              ) : null
            )}

            {selectedProPlan && (
              <div className="w-auto h-auto p-3 border-1 my-4 md:mb-0 md:mx-4 rounded-2xl overflow-x-auto overflow-y-auto shadow-2xl bg-[rgb(250,250,251)] hover:bg-[rgba(232,234,246,1)] hover:border-orange-400 transform hover:scale-105 transition-transform">
                <div className="flex flex-col justify-between items-start h-1/3 px-4 pb-0 text-[#0d0d0d]">
                  <div className="flex flex-wrap items-center my-2 ">
                    <span className="text-xs font-bold float-start">
                      <img src={R} alt="R" className="w-5 h-5 mx-1" />
                    </span>
                    <span className="font-semibold text-md float-start">
                      {selectedProPlan.plan_name} Plan
                    </span>
                    {selectedProPlan.plan_sale / selectedProPlan.plan_price !==
                      0 && (
                      <span className="text-xs font-bold float-end pl-20 pt-[-10px]">
                        <div className="bg-[#ffd22e] p-2">
                          {/* <del>{DEFAULT_CURRENCY} {Math.round(selectedProPlan.plan_sale / CURRENCY_CONVERSION)}</del> -{" "} */}
                          <del>{DEFAULT_CURRENCY} {(selectedProPlan.plan_sale / CURRENCY_CONVERSION).toFixed(2)}</del> -{" "}

                          <b>
                            save{" "}
                            {100 -
                              Math.ceil(
                                (selectedProPlan.plan_price /
                                  selectedProPlan.plan_sale) *
                                  100
                              )}
                            %
                          </b>
                        </div>
                      </span>
                    )}
                  </div>
                  <h3 className="text-2xl font-light tracking-widest uppercase">
                    PRO
                  </h3>
                  {/* <div className='text-3xl font-bold'>${DEFAULT_CURRENCY} {getDiscountedPriceMon(selectedProPlan)}</div> */}
                  <div className="text-3xl font-bold">
                    {DEFAULT_CURRENCY} {calculateMonthlyPayment(
                      selectedProPlan?.plan_sale,
                      (selectedProPlan.plan_price / CURRENCY_CONVERSION).toFixed(2),
                      selectedProPlan?.intervals,
                      selectedProPlan?.plan_name
                    )}
                    {/* {DEFAULT_CURRENCY} {calculateMonthlyPayment(
                      selectedProPlan?.plan_sale,
                      (selectedProPlan.plan_price / CURRENCY_CONVERSION).toFixed(2), // Ensure 2 decimal points
                      selectedProPlan?.intervals,
                      selectedProPlan?.plan_name
                    )} */}

                  </div>
                  <div className="text-xs font-light">
                    {/* {DEFAULT_CURRENCY}  {Math.round(selectedProPlan.plan_price / CURRENCY_CONVERSION)} will be billed{" "} */}
                    {DEFAULT_CURRENCY} {(selectedProPlan.plan_price / CURRENCY_CONVERSION).toFixed(2)} will be billed{" "}

                    {selectedProPlan?.plan_name?.replace("PRO ", "")}
                  </div>
                  <div className="relative flex pt-3">
                    {proPlans.map((plan) => (
                      <div
                        key={plan?.planId}
                        id={plan.plan_name}
                        className={`mr-3 text-sm cursor-pointer font-weight-700   transition ${selectePlan.toLowerCase() === plan?.plan_name.toLowerCase() ? "border-b-green-500 border-2":""}`
                      }
                        onClick={() => handleProPlanClick(plan.plan_name,plan?.activePlan)}
                      >
                        {plan.plan_name}
                      </div>
                    ))}
                    <div className="absolute left-0 w-[83px]"></div>
                  </div>
                  <hr className="w-11/12 mt-2 border-t-[rgba(245,245,245,0.7)]" />
                </div>
                <div className="flex flex-col justify-between py-4 h-2/3">
                  <ul className="px-8 space-y-2 text-gray-800 list-none">
                    {selectedProPlan?.plan_description.map((desc, index) => (
                      <li key={index} className="text-base">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-[#1d6362] mr-4"
                        />
                        {desc}
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={() => handleCheckout(selectedProPlan?.rp_plan_id)}
                    className="block w-[160px] py-2 mx-auto mt-8 text-lg font-bold text-white uppercase rounded-full bg-[#1d6362] hover:bg-orange-500 transition-colors"
                  >
                    {userActivePlan ? "Active": "Buy it"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Pricing;

import React, { useState, useEffect } from "react";
import Navbar from "../../Component/navbar.jsx";
import Footer from "../../Component/footer.jsx";
import cardi1 from "../../image/icons/cardi1.svg";
import cardi2 from "../../image/icons/cardi2.svg";
import cardi3 from "../../image/icons/cardi3.svg";
import map from "../../image/icons/map.svg";
import mapz from "../../image/icons/mapz.svg";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../apiConfig.js";
import userStore from "../../store/user.zustand.js";
import axios from "axios";
import { FaAngleLeft, FaArrowRightLong } from "react-icons/fa6";
import { IoIosRefresh } from "react-icons/io";
import { CiSearch, CiStar } from "react-icons/ci";
import step1 from "../../image/home/steps/step1.png";
import step2 from "../../image/home/steps/step2.png";
import step3 from "../../image/home/steps/step3.png";
import easyOnlineResume from "../../image/home/feature-designed/easy-online-resume.png";
import automaticSummary from "../../image/home/feature-designed/automatic-summary.png";
import yourDataIsSecure from "../../image/home/feature-designed/your-data-is-secure.png";
import generageResume from "../../image/home/feature-designed/generate-resume.png";
import optimizeYourResume from "../../image/home/feature-designed/optimize-resume.png";
import aiWritten from "../../image/home/feature-designed/ai-written.png";
import sampleResume1 from "../../image/home/sample-resumes/sample-1.png";
import sampleResume2 from "../../image/home/sample-resumes/sample-2.png";
import sampleResume3 from "../../image/home/sample-resumes/sample-3.png";
import sampleResume4 from "../../image/home/sample-resumes/sample-4.png";
import sampleResume5 from "../../image/home/sample-resumes/sample-5.jpg";
import leftArrow from "../../image/home/diverse-resume/left-arrow.png";
import rightArrow from "../../image/home/diverse-resume/right-arrow.png";
import plus from "../../image/home/subscribe/plus.png";
import emailIcon from "../../image/home/subscribe/email.png";
import bgHome1 from "../../image/home/background/bg-home-01.png";
import bgHome2 from "../../image/home/background/bg-home-02.png";
import bgHome3 from "../../image/home/background/bg-home-03.png";
import fakeResume1 from "../../image/home/sample-resumes/fake-resume-01.png";
import fakeResume2 from "../../image/home/sample-resumes/fake-resume-02.png";
import fakeResume3 from "../../image/home/sample-resumes/fake-resume-03.png";
import fakeResume4 from "../../image/home/sample-resumes/fake-resume-04.png";
import fakeResume5 from "../../image/home/sample-resumes/fake-resume-05.png";
import rightArrow1 from "../../image/home/icons/right-arrow.png";
import rightDottedArrow from "../../image/home/steps/right-arrow.png";
import aiResumeStore from "../../store/aiResumes.zustand.js";
import { toast } from "react-toastify";
import ResumeComponent from "../../Component/ResumeComponent.jsx";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube, Autoplay, Navigation } from "swiper/modules"; // Import necessary modules
import "swiper/css"; // Core Swiper styles
import "swiper/css/navigation"; // Navigation styles
import "swiper/css/pagination"; // Pagination styles (if needed)
import "swiper/css/effect-cube"; // Effect Cube styles
import Lp3 from "../../image/home/sample-resumes/lp3.png";


const points = [
  "Experience in Python - Pandas, Numpy for developing designing data-driven systems, Expertise in querying -",
  "SQL(Postgres DB is a good to have), Data modeling concepts, Data warehouse concepts, ETL, Version control - ",
  "Git Bitbucket Agile development Skills desired: Job schedulers - Autosys Airflow KDB",
];

const images = [bgHome1, bgHome2, bgHome3];
const resumeSamples = [sampleResume1, sampleResume2, sampleResume3, sampleResume4, sampleResume5];
const resumeSamples1 = [sampleResume1, sampleResume2, sampleResume3];


const Landp3 = () => {
  const [linker, setLinker] = useState("");

  const [visiblePoints, setVisiblePoints] = useState([]);
  const [index, setIndex] = useState(0);


  // Background Image Interval
  const [currentBgImage, setCurrentBgImage] = useState(images[0]);
  // const zustandUser = userStore((state) => state.user);
  const { isAuthenticated, setUser, setAuthenticated } = userStore()

  const navigate = useNavigate();
  const [startBuilding, setStartBuilding] = useState({
    email: "",
    error: false,
  });


  const fetchUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/users/me`, {
        withCredentials: true,
      });

      if (response?.data?.success) {
        // console.log("call")
        setUser(response?.data?.data)
        setAuthenticated(true)
        // userStore
        //   .getState()
        //   .setUser({ user: response?.data?.data, isAuthenticated: true });
        setLinker("/dashboard");
        // userStore.getState().setMsg({ user: response?.data?.data });
      } else {
        if (
          response?.data?.message === "Unauthorized" ||
          response?.data?.message === "User not found"
        ) {
          // redirect to login
          navigate("/login", { replace: true });
          setLinker("/login");
        }
        return;
      }
      return;
    } catch (error) {
      setLinker("/login");
      setAuthenticated(false)
      // userStore.getState().setUser({ isAuthenticated: false });
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    // const isAuthenticated = zustandUser.isAuthenticated;
    // if(isAuthenticated){
    fetchUser();
    // }else {
    // }
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Handle responsive resume display count
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setVisibleResumes(3); // Large devices: 3 resumes
      } else if (window.innerWidth >= 768) {
        setVisibleResumes(2); // Medium devices: 2 resumes
      } else {
        setVisibleResumes(1); // Small devices: 1 resume
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on initial render

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, resumeSamples.length - visibleResumes)
    );
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleResumes, setVisibleResumes] = useState(1);

  // Background Image Interval

  useEffect(() => {
    const interval = setInterval(() => {
      setVisiblePoints((prev) => {
        const newPoints = [...prev, points[index]];
        if (newPoints.length === points.length) {
          setTimeout(() => {
            setVisiblePoints([]);
            setIndex(0);
          }, 1000); // Adjust delay before restarting
        } else {
          setIndex((index + 1) % points.length);
        }
        return newPoints;
      });
    }, 2000); // Adjust delay between points

    return () => clearInterval(interval);
  }, [index]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgImage((prevImage) => {
        const currentIndex = images.indexOf(prevImage);
        const nextIndex = (currentIndex + 1) % images.length;
        return images[nextIndex];
      });
    }, 1000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="flex flex-col min-h-screen bg-white bg-center bg-cover">
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>ResumeBeen | AI-Powered Resume Builder for Your Career Success</title>
          <link rel="canonical" href="https://resumebeen.com/" />
          <meta property="og:site_name" content="ResumeBeen"></meta>
          <meta property="og:url" content="https://resumebeen.com/"></meta>
          <meta property="og:locale" content="en_EN"></meta>

          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content="@resumebeen"></meta>
          <meta name="twitter:creator" content="@resumebeen"></meta>

          <meta property="og:type" content="website"></meta>

          <meta name="title" content="ResumeBeen | AI-Powered Resume Builder for Your Career Success"></meta>
          <meta property="og:title" content="ResumeBeen | AI-Powered Resume Builder for Your Career Success"></meta>


          <meta name="description" content="ResumeBeen is an AI-powered resume builder designed to help you create standout, professional resumes and cover letters with ease. Customize templates, optimize content with Matching Mode Analysis, and get the tools you need to land your dream job faster."></meta>
          <meta property="og:description" content="ResumeBeen is an AI-powered resume builder designed to help you create standout, professional resumes and cover letters with ease. Customize templates, optimize content with Matching Mode Analysis, and get the tools you need to land your dream job faster."></meta>

          <meta name="keywords" content="ResumeBeen, AI resume builder, professional resumes, resume templates, resume customization, cover letter creator, Matching Mode Analysis, job application tools, personalized resumes, resume optimization, career advancement, resume creation platform, resume tools for job seekers"></meta>
          <meta property="og:keywords" content="ResumeBeen, AI resume builder, professional resumes, resume templates, resume customization, cover letter creator, Matching Mode Analysis, job application tools, personalized resumes, resume optimization, career advancement, resume creation platform, resume tools for job seekers"></meta>

        </Helmet>
      </>

      {/* <div className="my-4"> */}
      <Navbar />
      {/* </div> */}

      {/* Unlock Your Career */}
      <div className="relative flex flex-col items-center justify-center min-h-screen px-4 sm:mx-4 mx-0 bg-center bg-cover">
        {/* <img
          src={currentBgImage}
          alt="Background"
          className="absolute inset-0 object-cover object-top w-full h-full sm:object-fill "
          style={{ transform: "translateY(-11%)", opacity: "38%" }}
        /> */}
        <div className="relative flex flex-col lg:flex-row mx-2 space-y-8 lg:space-y-0 lg:space-x-8 mt-14 lg:mt-16">

          <div className="flex flex-col space-y-8 lg:w-[58%] justify-center items-center text-left px-4 sm:px-0 pt-3">
            <h1 className="font-archivo pl-0  ml-0 sm:ml-32 font-semibold text-4xl leading-tight text-left md:text-5xl md:leading-tight lg:text-[64px] lg:leading-[64px] text-transparent bg-clip-text bg-gradient-to-r from-[#003F3E] via-[#008583] to-[#FF7C37] ">
              Make your Resume Pass ATS Filters with Professional Format
            </h1>
            <div className="text-left">
              <p className="text-[#535353] pl-0  ml-0 sm:ml-36 font-normal text-lg leading-6 md:text-xl md:leading-7 font-roboto">
                Automatically Create Optimized Resume with AI-Powered Resume Builder
              </p>
            </div>
              <div
                className="flex w-[190px] md:w-[173px] h-[57px] rounded-[5px] p-[20px] gap-[20px] items-center justify-center py-4 px-2 md:px-4 text-base font-archivo font-normal leading-4 text-white bg-[#1F6463] cursor-pointer transition-colors duration-500 ease-in-out hover:bg-black"
                onClick={() => navigate("/dashboard")}
              >
                Create in Just 5 Min
              </div>
          </div>

          <div className="flex items-start justify-start lg:w-2/4">
            <img src={Lp3} alt="Sample Resume" className="h-[390px] w-[550px] md:h-[390px] md:w-[550px]" />
          </div>
        </div>


        <div className="mt-32 md:mx-2 ">
          <div className=" max-md:w-full">
            <div className="flex flex-col space-y-12 md:space-y-0 md:flex-row md:space-x-12">
              <div className="flex flex-col w-full lg:w-[589px] mt-4 space-y-4">
                <h2 className="text-xl font-small leading-5 font-archivo text-[#1F6463] uppercase">
                  Your AI resume builder
                </h2>
                <h3 className="font-archivo font-normal text-2xl sm:text-4xl leading-8 sm:leading-10 text-[#1C1C1C]">
                  Power your resume by use of <br /> advanced{" "}
                  <span className="text-[#EE702E]">
                    Artificial Intelligence.
                  </span>
                </h3>
                <p className="text-sm sm:text-base font-roboto font-normal leading-6 text-[#535353]">
                  Are you having trouble finding the right words for your
                  resume?
                </p>
                <p className="text-sm sm:text-base font-roboto font-normal leading-6 text-[#535353]">
                  Use our AI-powered resume builder to <b>automatically generate
                    your entire resume</b>. The AI Resume Writer can write professional
                  sections for your <b>resume summary</b>, <b>work experience</b>,
                  and <b>project details </b> with a consistent tone throughout
                  the document. Try it for free and get your AI-generated
                  Resume ready in minutes.
                </p>
                <div
                  className=" flex rounded-[5px] p-[20px] gap-[15px] w-[230.43px] h-[57px] bg-[#1F6463] cursor-pointer transition-colors duration-500 ease-in-out hover:bg-black"
                  onClick={() => navigate("/dashboard")}
                >
                  <button className="absolute text-base  font-medium font-archivo leading-[17.41px] text-[#ffffff] ">
                    Try AI Resume Now
                  </button>
                  {/* <img
                    src={rightArrow1}
                    alt="right-arrow"
                    className="w-[28.43px] h-[16px] text-[#ffffff]"
                  /> */}
                </div>
              </div>
              <div className="w-full lg:w-[532px] z-auto border border-[#D6D6D6] shadow-home-container-2-b rounded-[30px_30px_30px_30px] border-t border-transparent">
                <div className="bg-[#D7D7D7] rounded-tl-xl rounded-tr-xl  w-full flex justify-between px-4 py-2">
                  <div className="flex items-center justify-between space-x-4">
                    {/* square - 1 */}
                    <div className="w-20 h-4 bg-[#F2F2F2] transform skew-x-12"></div>

                    {/* square - 2 */}
                    <div className="w-4 h-2 bg-[#F2F2F2] transform skew-x-12"></div>
                  </div>
                  <div className="flex items-center justify-between space-x-4">
                    {/* square -1 */}
                    <div className="bg-[#5BBD6A] w-3 h-3"></div>

                    {/* square - 2 */}
                    <div className="bg-[#EA625C] w-3 h-3"></div>
                  </div>
                </div>
                <div className="bg-[#E7E7E7] p-2 flex justify-between space-x-2">
                  <div className="flex items-center space-x-2">
                    <FaAngleLeft className="text-[#5A5A5A]" size="14" />
                    <FaArrowRightLong className="text-[#5A5A5A]" size="14" />
                    <IoIosRefresh className="text-[#5A5A5A]" size="14" />
                  </div>
                  <div className="bg-[#FFFFFF] h-4 flex items-center justify-between w-full">
                    <CiSearch className="text-[#5A5A5A]" size="14" />
                    <CiStar className="text-[#5A5A5A]" size="14" />
                  </div>
                  <div className="flex flex-col justify-between">
                    <p className="w-1 h-1 bg-[#5A5A5A] rounded-full"></p>
                    <p className="w-1 h-1 bg-[#5A5A5A] rounded-full"></p>
                    <p className="w-1 h-1 bg-[#5A5A5A] rounded-full"></p>
                  </div>
                </div>
                <div className="flex flex-col p-4 space-y-4">
                  <h2 className="text-[#1C1C1C] font-archivo font-semibold text-base leading-6">
                    Title
                  </h2>
                  <div className="w-full border p-2 border-[#D3D3D3] text-[#535353] font-archivo font-normal text-base text-start">
                    Python-ETL /TPRM
                  </div>
                  <h2 className="text-[#1C1C1C] font-archivo font-semibold text-base leading-6">
                    Description
                  </h2>
                  <div className="w-full h-auto border border-[#D3D3D3] text-[#535353] font-archivo font-normal text-base text-start">
                    <ul>
                      {points.map((point, i) => (
                        <li
                          key={i}
                          className={`text-[#535353] font-archivo font-normal text-sm p-1 transition-opacity duration-500 ${visiblePoints.includes(point)
                            ? "opacity-100"
                            : "opacity-0"
                            }`}
                        >
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col m-4 space-y-8 bg-white md:p-16">
        {/* Heading */}
        <h2 className="text-[#1F6463] font-archivo font-medium text-lg md:text-2xl leading-5 text-center">
          AI-Generated Resume Examples
        </h2>
        <h4 className="font-archivo text-center text-3xl md:text-5xl leading-8 md:leading-10 text-[#1C1C1C]">
          Diverse Resume formats: Which one is right for you?
        </h4>

        {/* Container for the resume samples and arrows */}
        <div className="relative flex items-center justify-center w-full overflow-hidden">
          {/* Swiper Component */}
          <Swiper
            modules={[Autoplay, Navigation]} // Add Navigation module here
            spaceBetween={16} // Space between slides
            loop={true} // Infinite looping
            autoplay={{
              delay: 3000, // 3-second autoplay delay
              disableOnInteraction: false, // Continue autoplay after interaction
            }}
            navigation={{
              prevEl: '.custom-prev', // Custom prev button
              nextEl: '.custom-next', // Custom next button
            }} // Custom navigation
            breakpoints={{
              320: {
                slidesPerView: 1, // 1 slide for small screens
                spaceBetween: 8, // Less space between slides on small screens
              },
              768: {
                slidesPerView: 2, // 2 slides for tablets and medium screens
                spaceBetween: 16, // Standard space
              },
              1024: {
                slidesPerView: 3, // 3 slides for large screens (laptops)
                spaceBetween: 16, // Standard space
              },
            }}
            className="w-full"
          >
            {resumeSamples.map((sample, index) => (
              <SwiperSlide key={index} className="relative group">
                {/* Image */}
                <img
                  src={sample}
                  alt={`resume-${index + 1}`}
                  className="object-cover w-full h-auto mx-auto"
                  onClick={() => navigate('/dashboard')} // Navigate to login page on click
                />

                <div
                  className="absolute bottom-6 left-1/2 transform -translate-x-1/2 bg-green-700 text-white text-center py-2 px-4 w-1/2 opacity-0 translate-y-4 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300 ease-in-out rounded-md cursor-pointer"
                  onClick={() => navigate('/dashboard')}
                >
                  Use This Template
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Navigation Arrows */}
          <div className="custom-prev absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#B1B1B1] rounded-full w-[59px] h-[59px] flex items-center justify-center cursor-pointer z-10">
            <img src={leftArrow} alt="left" className="object-cover" />
          </div>
          <div className="custom-next absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#B1B1B1] rounded-full w-[59px] h-[59px] flex items-center justify-center cursor-pointer z-10">
            <img src={rightArrow} alt="right" className="object-cover" />
          </div>
        </div>
      </div>
      {/* About Templates */}
      <div className="flex flex-col w-full px-6 mt-32 space-y-10 md:space-y-0  md:px-5 md:flex-row md:space-x-12">

        <div className="border-[#D1D1D1] flex flex-col space-y-4 md:space-y-0  md:flex-row w-full justify-center items-center">
          <div className="flex space-x-4 md:space-x-0 md:flex-col">
            <img src={fakeResume1} alt="fake-resume-1" />
            <img src={fakeResume2} alt="fake-resume-2" />
          </div>
          <div className="flex space-x-4 md:space-x-0 md:flex-col">
            <img src={fakeResume3} alt="fake-resume-4" />
            <img src={fakeResume4} alt="fake-resume-4" />
          </div>
          <div className="flex space-x-4 md:space-x-0 md:flex-col">
            <img src={fakeResume5} alt="fake-resume-5" />
          </div>
        </div>
        <div className="flex flex-col items-start justify-center w-full space-y-6 ml-0 pl-5">
          <h2 className="text-xl font-bold leading-5 font-archivo text-[#1F6463] uppercase">
            100x your opportunities
          </h2>
          <h3 className="font-archivo font-normal text-4xl leading-10 text-[#1C1C1C]">
            Stand out with our <br /> professional
            <span className="text-[#EE702E]"> templates.</span>
          </h3>
          <p className="text-[#535353] font-roboto font-normal text-sm leading-6">
            Wondering which template best shows your professional profile? {" "}
            <br className="hidden lg:block" />
            Get hired fast with an eye-catching resume. Choose from a diverse{" "}
            <br className="hidden lg:block" />
            collection of ATS-friendly resume templates designed by a team of {" "}
            <br className="hidden lg:block" />
            HR experts. Customize any template in any way you want.{" "}
            <br className="hidden lg:block" />
            Explore more than a million possible design combinations.
          </p>

          <div
            onClick={() => navigate("/login")}
            className="flex rounded-[5px] p-[20px] gap-[15px] w-[245.43px] h-[57px] bg-[#1F6463] cursor-pointer transition-colors duration-500 ease-in-out hover:bg-black"
          >
            <button className="text-base  font-medium font-archivo leading-[17.41px] text-[#ffffff] ">
              See Resume Samples
            </button>
            <img
              src={rightArrow1}
              alt="right-arrow"
              className="w-[28.43px] h-[16px] text-[#ffffff]"
            />
          </div>
        </div>
      </div>


      {/* Craft your success */}
      <div className="flex flex-col items-center mt-12 space-y-6 sm:px-24 sm:my-24 max-md:px-6 max-md:py-12 max-md:w-full">
        <h2 className="text-5xl font-light leading-[52px] text-center text-[#1C1C1C] font-archivo">
          Craft your success in{" "}
          <span className="text-[#EE702E] border-b-2 border-b-[#EE702E]">
            {" "}
            3 simple{" "}
          </span>{" "}
          steps
        </h2>
        <p className="font-roboto font-light text-[20px] leading-7 text-center">
          Let AI build your resume and choose from our curated templates.{" "}
          <span className="text-[#1F6463]">Start now!</span> Choose from our
          handpicked <br />
          selection of templates to give your resume a professional edge.
          Success is just two steps away!
        </p>

        <div className="flex flex-col md:flex-row md:space-x-6">
          <div className="flex flex-col  relative items-center justify-center p-8 space-y-2 text-center min-w-[200px]">
            <img src={step1} alt="step1" className="w-16 h-16" />
            <img
              src={rightDottedArrow}
              className="absolute transform -translate-y-1/2 hidden md:block  w-28 left-[8rem] lg:left-[15.5rem] lg:w-56"
              style={{ top: "27%" }}
              alt="*------------>"
            />

            <h4 className="text-sm font-semibold text-center uppercase font-roboto">
              STEP-1
            </h4>
            <p className="font-roboto font-semibold leading-4 text-sm text-center text-[#535353]">
              Start:{" "}
              <span className="text-sm font-normal text-center">
                Ready to create a standout resume in minutes? Our AI Resume
                Builder is here to guide you through the process effortlessly.
              </span>
            </p>
          </div>
          <div className="flex flex-col  relative items-center justify-center p-8 space-y-2 text-center min-w-[200px]">
            <img src={step2} alt="step2" className="w-16 h-16" />
            <img
              src={rightDottedArrow}
              className="absolute transform -translate-y-1/2 hidden md:block left-[10rem] lg:left-[15.5rem] lg:w-56 w-28"
              style={{ top: "27%" }}
              alt="*------------>"
            />
            <h4 className="text-sm font-semibold text-center uppercase font-roboto">
              STEP-2
            </h4>
            <p className="font-roboto font-semibold leading-4 text-sm text-center text-[#535353]">
              Build:{" "}
              <span className="text-sm font-normal text-center">
                our AI-powered tool simplifies the resume-building process,
                ensuring you present your best self to potential employers.{" "}
              </span>
            </p>
          </div>
          <div className="flex flex-col relative items-center justify-center p-8 space-y-2 text-center min-w-[200px]">
            <img src={step3} alt="step3" className="w-16 h-16" />
            <h4 className="text-sm font-semibold text-center uppercase font-roboto">
              STEP-3
            </h4>
            <p className="font-roboto font-semibold leading-4 text-sm text-center text-[#535353]">
              Download:{" "}
              <span className="text-sm font-normal text-center">
                Download your AI-generated resume in your preferred format—PDF,
                Word, or text—and apply to your dream jobs with confidence{" "}
              </span>
            </p>
          </div>
        </div>

        <div
          className="flex rounded-[5px] p-[20px] gap-[15px] w-[230.43px] h-[57px] bg-[#1F6463] cursor-pointer transition-colors duration-500 ease-in-out hover:bg-black"
          onClick={() => navigate("/dashboard")}
        >
          <button className="text-base  font-medium font-archivo leading-[17.41px] text-[#ffffff] ">
            Create My Resume
          </button>
          <img
            src={rightArrow1}
            alt="right-arrow"
            className="w-[28.43px] h-[16px] text-[#ffffff]"
          />
        </div>
      </div>

      {/* Feature designed */}
      <div className="mx-1 my-12 font-light text-center font-archivo">
        <h2 className="text-5xl leading-[52px] text-[#EE702E] md:text-5xl md:leading-[40px] sm:text-3xl sm:leading-[32px]">
          Features{" "}
          <span className="text-[#1C1C1C]">
            designed to help you win your <br className="" /> dream job
          </span>
        </h2>
      </div>

      {/* Containers */}
      <div className="p-8 md:p-16 mt-12  bg-[#E8E8E8]">
        <div className="flex flex-col w-full space-y-4 ">
          {/* container-1 */}
          <div className="flex flex-col w-full space-x-0 space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row">
            {/* Left */}
            <div className="flex w-full lg:w-1/2 rounded-2xl  space-x-4 md:space-x-8 bg-[#FFFFFF] py-8">
              {/* left border  */}
              <div className="bg-[#EE702E] rounded-r-md w-8 sm:w-5 h-16 mt-1"></div>
              {/* Image */}
              <img
                src={easyOnlineResume}
                alt="easy-online-resume"
                className="h-20 w-52 md:w-56"
              />

              {/* Container */}
              <div className="flex flex-col px-1 space-y-10 sm:px-12">
                <h3 className="font-archivo font-light mr-4  md:mr-20 text-3xl leading-8 text-[#1C1C1C]">
                  Easy Online Resume Builder
                </h3>
                <div className="flex flex-col pr-4 space-y-4 md:pr-12">
                  <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                    ResumeBeen AI Resume Builder is a user-friendly tool designed
                    to help you create a professional and polished resume
                    quickly and effortlessly.
                  </p>
                  <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                    Whether you're a first-time job seeker or a seasoned
                    professional, our platform guides you through each step of
                    the resume-building process.
                  </p>
                  <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                    With a wide selection of customizable templates, expert
                    tips, and an intuitive interface, you can craft a resume
                    that showcases your skills and experience in the best
                    possible light—all without needing any design or technical
                    skills.
                  </p>
                  <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                    Save time, avoid stress, and create a resume that gets
                    noticed by employers with ResumeBeen AI Resume Builder.
                  </p>
                </div>
              </div>
            </div>

            {/* Right */}
            <div className="flex flex-col w-full space-y-4 lg:w-1/2">
              <div className="flex w-full rounded-2xl space-x-4 md:space-x-8 bg-[#FFFFFF] py-8">
                {/* left border  */}
                <div className="bg-[#EE702E] rounded-r-md w-10 sm:w-6  h-16 mt-1"></div>
                {/* Image */}
                <img
                  src={automaticSummary}
                  alt="automatic-summary"
                  className="h-20 w-52 md:w-56"
                />

                {/* Container */}
                <div className="flex flex-col px-1 space-y-10 sm:px-12">
                  <h3 className="font-archivo font-light mr-4  md:mr-20 text-3xl leading-8 text-[#1C1C1C]">
                    Automatic Summary Generator
                  </h3>
                  <div className="flex flex-col pr-4 space-y-4 md:pr-12">
                    <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                      The Automatic Summary Generator is a powerful tool
                      designed to help you create a compelling and personalized
                      summary for your resume in seconds. Simply input your key
                      skills, experiences, and career goals, and our generator
                      will craft a polished, professional summary that
                      highlights your strengths and aligns with the job you're
                      applying for.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex w-full rounded-2xl space-x-4 md:space-x-8 bg-[#FFFFFF] py-8">
                {/* left border  */}
                <div className="bg-[#EE702E] rounded-r-md w-12 sm:w-6  h-16 mt-1"></div>
                {/* Image */}
                <img
                  src={yourDataIsSecure}
                  alt="your-data-is-secure"
                  className="h-20 w-52 md:w-56"
                />

                {/* Container */}
                <div className="flex flex-col px-1 space-y-10 sm:px-12">
                  <h3 className="font-archivo font-light mr-4  md:mr-20 text-3xl leading-8 text-[#1C1C1C]">
                    Your data is secure with ResumeBeen
                  </h3>
                  <div className="flex flex-col pr-4 space-y-4 md:pr-12">
                    <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                      At ResumeBeen, your privacy and security
                      are our top priorities. We use advanced encryption and
                      secure servers to protect your personal information at
                      every step of the resume-building process. Whether you're
                      entering your contact details, work history, or any other
                      sensitive data, rest assured that it is handled with the
                      utmost care and security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* container-2 */}
          <div className="flex flex-col w-full space-x-0 space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row">
            {/* Left */}
            <div className="flex flex-col w-full space-y-4 lg:w-1/2">
              <div className="flex w-full rounded-2xl space-x-4 md:space-x-8 bg-[#FFFFFF] py-8">
                {/* left border  */}
                <div className="bg-[#EE702E] rounded-r-md w-10 sm:w-5  h-16 mt-1"></div>
                {/* Image */}
                <img
                  src={generageResume}
                  alt="generate-resume"
                  className="h-20 w-52 md:w-56"
                />

                {/* Container */}
                <div className="flex flex-col px-1 space-y-10 sm:px-12">
                  <h3 className="font-archivo font-light mr-4  md:mr-20 text-3xl leading-8 text-[#1C1C1C]">
                    Generate Resume with your Job Title
                  </h3>
                  <div className="flex flex-col pr-4 space-y-4 md:pr-12">
                    <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                      This feature allows you to create a customized resume that
                      aligns perfectly with the role you're applying for. Simply
                      enter your job title, and our tool will provide
                      industry-specific suggestions for skills, experiences, and
                      keywords that will make your resume stand out.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex w-full rounded-2xl space-x-4 md:space-x-8 bg-[#FFFFFF] py-8">
                {/* left border  */}
                <div className="bg-[#EE702E] rounded-r-md w-10 sm:w-6  h-16 mt-1"></div>
                {/* Image */}
                <img
                  src={aiWritten}
                  alt="ai-written"
                  className="h-20 w-52 md:w-56"
                />

                {/* Container */}
                <div className="flex flex-col px-1 space-y-10 sm:px-12">
                  <h3 className="font-archivo font-light mr-4  md:mr-20 text-3xl leading-8 text-[#1C1C1C]">
                    AI written phrases
                  </h3>
                  <div className="flex flex-col pr-4 space-y-4 md:pr-12">
                    <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                      Enhance your resume with expertly crafted content using
                      our AI-Written Phrases feature. Designed to help you
                      articulate your skills and experience with precision, this
                      tool generates professional, impactful phrases tailored to
                      your unique background and the job you're targeting.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Right */}
            <div className="flex w-full lg:w-1/2 rounded-2xl space-x-4 md:space-x-8 bg-[#FFFFFF] py-8 ">
              {/* left border  */}
              <div className="bg-[#EE702E] rounded-r-md w-10 sm:w-6  h-16 mt-1"></div>
              {/* Image */}
              <img
                src={optimizeYourResume}
                alt="optimize-your-resume"
                className="h-20 w-52 md:w-56"
              />

              {/* Container */}
              <div className="flex flex-col px-1 space-y-10 lg:px-12">
                <h3 className="font-archivo font-light mr-4  md:mr-20 text-3xl leading-8 text-[#1C1C1C]">
                  Optimize your Resume
                </h3>
                <div className="flex flex-col pr-4 space-y-4 md:pr-12">
                  <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                    Maximize your chances of landing your dream job with our
                    "Optimize Your Resume" feature. This tool analyzes your
                    resume against industry-specific standards and the latest
                    hiring trends to ensure it meets the expectations of
                    recruiters and ATS (Applicant Tracking Systems).
                  </p>
                  <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                    It provides personalized recommendations to improve your
                    resume's structure, content, and keywords, helping you
                    create a polished, professional document that stands out
                    from the competition.
                  </p>
                  <p className="font-roboto font-normal leading-5 text-sm text-[#545454]">
                    Whether you're looking to fine-tune your existing resume or
                    start from scratch, our optimization tool ensures your
                    resume is tailored to make a strong impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#ffffff]">
        <h2 className="text-[#1F6463] font-archivo font-medium text-lg md:text-2xl leading-5 text-center pt-16">
          ATS Ready Resume Templates
        </h2>
        <h4 className="font-archivo text-center text-3xl md:text-5xl leading-10 md:leading-10 text-[#1C1C1C] pt-4 ">
          Stand out in the job market with AI-crafted resumes.
        </h4>
        <h4 className="font-archivo text-center text-3xl md:text-5xl leading-2 md:leading-2 text-[#1C1C1C] pt-4 pb-4">
          Create stunning, ATS-ready resumes in minutes.
        </h4>
        <ResumeComponent />
      </div>



      <div className="mx-1 mt-28 max-md:max-mx-20 max-md:max-mt-36 max-md:mx-5">
        <section className="flex flex-col px-1 text-5xl font-bold text-center text-black leading-[78.2px] max-md:text-3xl max-md:px-5">
          <header className="w-full max-md:max-w-full">
            Secure global opportunities with our standout resume
          </header>
          <div className="relative self-center mt-20 w-full aspect-[2] max-w-[1099px] max-md:mt-10 max-md:max-w-full max-md:aspect-[2]">
            <div>
              <img
                loading="lazy"
                src={map}
                alt="Standout resume visual representation"
                className="absolute z-0 self-center w-auto h-auto"
              />
            </div>
            <div>
              <img
                loading="lazy"
                src={mapz}
                alt="Standout resume visual representation"
                className="absolute z-10 w-full h-full mt-8"
              />
            </div>
          </div>
        </section>
      </div>

      <div className="mx-10 my-40 max-md:max-mx-20 max-md:my-16">
        <section className="flex flex-col px-1 max-md:px-5">
          <h1 className="self-center text-6xl font-bold text-center text-black leading-[57.96px] max-md:max-w-full max-md:text-3xl">
            Build, Personalise, and Shine
          </h1>
          <div className="w-full mt-20 max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col items-center justify-center">
                <img src={cardi1} alt="card" loading="lazy" />
                <div className="mt-4 text-lg font-semibold">
                  Select Your Template
                </div>
                <div className="mt-2 text-center">
                  Choose from our range of professionally designed templates
                  tailored for success.
                </div>
              </div>
              <div className="flex flex-col items-center justify-center ">
                <img src={cardi2} alt="card" loading="lazy" />
                <div className="mt-4 text-lg font-semibold">
                  Personalize Your Details
                </div>
                <div className="mt-2 text-center">
                  Easily input your information, skills, and experience with our
                  user-friendly customization tools.
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <img src={cardi3} alt="card" loading="lazy" />
                <div className="mt-4 text-lg font-semibold">
                  Download and Shine
                </div>
                <div className="mt-2 text-center">
                  Download your polished resume instantly and make a lasting
                  impression on employers
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Register Now */}
      <div className="flex flex-col w-11/12 mx-auto lg:flex-row justify-between m-2 lg:m-12 p-6 border-[#929292] border-1 space-y-6 lg:space-y-0  lg:p-10">
        <div className="flex flex-row items-start justify-center space-x-4 space-y-0 md:space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
          <img
            src={plus}
            alt="plus"
            className=" text-[#EE702E] w-6 h-6 lg:w-9 lg:h-9"
          />

          <div className="w-full">
            <h2 className="font-archivo  flex items-center flex-wrap font-normal text-xl lg:text-3xl leading-6 lg:leading-8 text-[#1C1C1C]">
              Try Resumebeen's professional resume builder now.
            </h2>
            <h2 className="text-[#EE702E] font-archivo flex items-center flex-wrap font-medium text-xl lg:text-3xl leading-6 lg:leading-8">
              Start for free!
            </h2>
          </div>
        </div>

        <div className="flex items-center justify-center w-full">
          <div className="flex w-full sm:w-[440px] justify-between items-center bg-white border p-[20px] gap-[12px] sm:gap-[20px] shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)] rounded border-[#929292]">

            {/* Mobile: 1/6 width for email icon */}
            <div className="w-[1/6] sm:w-auto">
              <img
                src={emailIcon}
                alt="email"
                className="w-[25.33px] h-[20px] text-center"
              />
            </div>

            {/* Mobile: 3/6 width for input */}
            <div className="w-[3/6] sm:w-[229px]">
              <input
                type="email"
                placeholder="Your Email"
                className={`font-archivo p-1 font-medium w-full leading-4 text-[#929292] text-xs sm:text-sm border-1 rounded-md border-transparent focus:border-[#929292] focus:outline-none ${startBuilding.email ? "border-[#929292]" : ""}`}
                value={startBuilding.email}
                onChange={(e) =>
                  setStartBuilding((prev) => ({
                    error: false,
                    email: e.target.value,
                  }))
                }
              />
              <p className="text-red-600">
                {!emailRegex.test(startBuilding.email) && startBuilding.email
                  ? "Invalid email"
                  : ""}
              </p>
            </div>

            {/* Mobile: 2/6 width for button */}
            <div className="w-[2/6] sm:w-[50%]">
              <button
                className="bg-[#EE702E] w-full sm:w-[100%]  rounded-md text-white font-archivo sm:font-normal font-light text-xs sm:text-sm leading-4 p-2"
                onClick={() => {
                  if (emailRegex.test(startBuilding.email)) {
                    if (isAuthenticated) {
                      navigate(`/dashboard`);
                      return;
                    }
                    navigate(`signup?email=${startBuilding.email}`);
                  } else {
                    setStartBuilding((prev) => ({ ...prev, error: true }));
                  }
                }}
              >
                Start Building
              </button>
            </div>

          </div>
        </div>

      </div>

      <Footer />
    </div>
  );
};
export default Landp3;

import React, { useState } from "react";
// import { Navigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../apiConfig";
import CustomButton from "../Reusable/CustomButton";
import { toast } from "react-toastify";
import hirebeen from "../image/hirebeenLogo.png";
import H from "../image/H.png";
import resumeBeen from "../image/ResumeBeen.png";
import R from "../image/R.svg";
// import Group1 from "../image/Group1.svg";
import Group2 from "../image/Group2.svg";
import Group3 from "../image/Group3.svg";
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // if (status) {
  //   return <Navigate to="/login" />;
  // }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleForgotEmail = async () => {
    if (!email) {
      toast.error("Please enter your email address", { autoClose: 2500 });
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address", { autoClose: 2500 });
      return;
    }

    try {
      setLoading(true);
      const resp = await axios.post(`${API_URL}/api/v1/users/forgot-password`, {
        email,
      });
      setLoading(false);

      if (resp.data.success) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        setEmail("")
        setStatus(true);
      } else {
        toast.error(resp.data.message, { autoClose: 2500 });
      }
      return
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || "An error occurred", {
        autoClose: 2500,
      });
    }
  };

  const handleDisableBtn = () => {
    return email.trim() === "" ||!emailRegex.test(email);
  }

  return (
    <div className="grid h-screen grid-cols-2 gap-0 max-md:grid-cols-1">
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Forgot Password - ResumeBeen | Recover Your Account Access</title>
            <link rel="canonical" href="https://resumebeen.com/forgot-password" />
            <meta property="og:site_name" content="ResumeBeen"></meta>
            <meta property="og:url" content="https://resumebeen.com/forgot-password"></meta>
            <meta property="og:locale" content="en_EN"></meta>

            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@resumebeen"></meta>
            <meta name="twitter:creator" content="@resumebeen"></meta>

            <meta property="og:type" content="website"></meta>

            <meta name="title" content="Forgot Password - ResumeBeen | Recover Your Account Access"></meta>
            <meta property="og:title" content="Forgot Password - ResumeBeen | Recover Your Account Access"></meta>


            <meta name="description" content="Forgot your password? Easily reset it here to regain access to your ResumeBeen account and continue building your professional resume. Just enter your email, and we’ll guide you through the steps to securely restore access."></meta>
            <meta property="og:description" content="Forgot your password? Easily reset it here to regain access to your ResumeBeen account and continue building your professional resume. Just enter your email, and we’ll guide you through the steps to securely restore access."></meta>

            <meta name="keywords" content="ResumeBeen forgot password, reset password, account recovery, password assistance, resume builder login help, recover ResumeBeen account, password reset instructions, secure account access"></meta>
            <meta property="og:keywords" content="ResumeBeen forgot password, reset password, account recovery, password assistance, resume builder login help, recover ResumeBeen account, password reset instructions, secure account access"></meta>

        </Helmet>      
      </>


      {/* Left Side - Images */}
      <div className="relative w-full h-full bg-conic-gradient max-md:hidden">
        <div className="flex gap-2.5 self-start p-6" onClick={() => navigate("/")} >
          <img
            loading="lazy"
            alt="R"
            src={R}
            className="shrink-1 aspect-[0.75] w-[29px]"
          />
          <img
            loading="lazy"
            src={resumeBeen}
            alt="ResumeBeen"
            className="shrink-0 my-auto max-w-full aspect-[5.56] w-[146px]"
          />
        </div>
        <div className="absolute top-20 left-6 text-5xl font-bold text-white leading-[70px] max-md:top-10 max-md:left-4 max-md:text-4xl max-md:leading-[68px]">
          Build your successful resume instantly using AI
        </div>
        <div className="absolute bottom-0 left-0 flex justify-between w-full p-6 max-md:hidden">
          <img
            loading="lazy"
            alt="Resume"
            src={Group2}
            className="w-72"
          />
          <img
            loading="lazy"
            alt="Resume"
            src={Group3}
            className="w-72"
          />
        </div>
      </div>
  
      {/* Right Side - Form */}
      <div className="flex flex-col items-center justify-center h-full max-w-md p-6 mx-auto rounded-lg">
        <div className="w-full">
          <h2 className="mb-4 text-3xl font-semibold text-center text-gray-900">
            Forgot Password
          </h2>
          <h5 className="mb-6 text-center text-gray-600">
            Please enter your registered email address. We will send a link to reset your password.
          </h5>
          <form className="w-full">
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 text-gray-700">
                Email
              </label>
              <input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2"
                id="email"
                placeholder="Enter your registered email"
              />
            </div>
            <div className="flex justify-center">
              <CustomButton
                onClick={handleForgotEmail}
                loading={loading}
                className="px-6 py-2 text-white rounded-md shadow-md focus:outline-none focus:ring-2"
                disabled={handleDisableBtn()}
              >
                Send Link
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  
  
  
};

export default ForgotPassword

import React, { useState, useEffect, useRef } from "react";
import google from "../image/google.png";
import hirebeen from "../image/hirebeenLogo.png";
import H from "../image/H.png";
import resumeBeen from "../image/ResumeBeen.png";
import R from "../image/R.svg";
import hide from "../image/icons/hide.png";
import view from "../image/icons/view.png";
import Group2 from "../image/Group2.svg";
import Group3 from "../image/Group3.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./Signup.css";
import CustomButton from "../Reusable/CustomButton";
import axios from "axios";
import { API_URL } from "../apiConfig";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";


const Signup = () => {
  const [searchParams] = useSearchParams();
  const termsAndConditionsRef = useRef(null);

  const dashboardEmail = searchParams.get("email");
  const navigate = useNavigate();

  const [showTC, setShowTC] = useState(false);
  const [visible, setVisible] = useState();
  const [visible1, setVisible1] = useState();
  const [signUp, setSignUp] = useState({
    firstName: "",
    email: dashboardEmail || "",
    password: "",
    confirmPassword: "",
    teamsAndCondition: false,
  });
  const [errMsg, setErrMsg] = useState({
    firstName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isEmailValid: true,
    isPasswordValid: true,
  });
  const [loading, setLoading] = useState(false);
  const [tc, setTC] = useState("");
  const [tcId, setTCId] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTCOAuth, setShowTCOAuth] = useState(false);
  const [pp, setPP] = useState("");
  const [ppId, setPPId] = useState("")

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: termsAndConditionsRef.current,
      threshold: 1,
    });

    if (termsAndConditionsRef.current) {
      observer.observe(termsAndConditionsRef.current.lastElementChild);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setSignUp((prev) => ({ ...prev, password: password }));

    // Validate password
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;
    if (passwordRegex.test(password)) {
      setErrMsg((prev) => ({ ...prev, password: "", isPasswordValid: true }));
    } else {
      setErrMsg((prev) => ({
        ...prev,
        password:
          "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a digit, and a special character.",
        isPasswordValid: false,
      }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    setSignUp((prev) => ({ ...prev, confirmPassword }));

    // Check if passwords match
    if (confirmPassword === signUp.password) {
      setErrMsg((prev) => ({
        ...prev,
        confirmPassword: "",
        isConfirmPasswordValid: true,
      }));
    } else {
      setErrMsg((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match.",
        isConfirmPasswordValid: false,
      }));
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setSignUp((prev) => ({ ...prev, email: email }));

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setErrMsg((prev) => ({ ...prev, email: "", isEmailValid: true }));
    } else {
      setErrMsg((prev) => ({
        ...prev,
        email: "Please enter a valid email.",
        isEmailValid: false,
      }));
    }
  };

  const handleSignUp = async () => {
    try {

      if (showTCOAuth) {
        reachGoogle()
        return
      }

      if (!tcId) {
        toast.error("Please select a terms and conditions.", {
          autoClose: 2500,
        });
        return;
      }

      if (!ppId) {
        toast.error("Please select a terms and conditions.", {
          autoClose: 2500,
        });
        return;
      }

      setLoading(true);
      const res = await axios.post(`${API_URL}/api/v1/users/register`, {
        firstName: signUp.firstName,
        email: signUp.email,
        password: signUp.password,
        tcId: tcId,
        ppId: ppId
      });
      setLoading(false);
      if (!res?.data?.success) {
        toast.error(res?.data?.message, {
          autoClose: 1500,
        });
        setShowTC(false);
        return;
      }
      toast.success(res?.data?.message, {
        autoClose: 2500,
      });

      setSignUp({
        email: "",
        password: "",
        firstName: "",
        confirmPassword: "",
      });
      navigate("/login")
      return;
    } catch (err) {
      console.log(err);
      setShowTC(false);
      const errorMessage =
        err?.response?.data?.message || "An unexpected error occurred.";
      toast.error(errorMessage, {
        autoClose: 2500,
      });
      setLoading(false);
      return;
    }
  };

  const reachGoogle = () => {
    window.location.href = `${API_URL}/api/v1/users/auth/google`;
  };

  const disableSignUp = () => {
    return (
      !signUp.firstName ||
      !signUp.email ||
      !signUp.password ||
      !errMsg.isEmailValid ||
      !errMsg.isPasswordValid ||
      errMsg.confirmPassword ||
      errMsg.firstName ||
      signUp.password !== signUp.confirmPassword ||
      !signUp.teamsAndCondition
    );
  };

  useEffect(() => {
    const getTc = async () => {
      try {
        const tc = await axios.get(`${API_URL}/api/v1/tc/tc`);
        const pp = await axios.get(`${API_URL}/api/v1/pp/pp`);
        setTC(tc?.data?.data?.content);
        setPP(pp?.data?.data?.content)
        setTCId(tc?.data?.data?._id?.toString());
        setPPId(pp?.data?.data?._id?.toString());
      } catch (error) {
        console.error("Error fetching TC:", error);
      }
    };

    getTc();
  }, []);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if ((scrollTop + clientHeight) + 5 >= scrollHeight) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  useEffect(() => {
    const currentRef = termsAndConditionsRef.current;

    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleSubmit = () => {
    if (!signUp?.firstName) {
      toast.error("Please enter your name.", {
        autoClose: 2500,
      });
      return;
    } else if (!signUp?.email) {
      toast.error("Please enter your email address.", {
        autoClose: 2500,
      });
      return;
    } else if (!signUp?.password) {
      toast.error("Please enter your password.", {
        autoClose: 2500,
      });
      return;
    } else if (signUp?.password !== signUp?.confirmPassword) {
      toast.error("Passwords do not match.", {
        autoClose: 2500,
      });
      return;
    } else {
      setShowTC(true)
    }
    return
  }

  return (
    <div className="grid h-screen grid-cols-2 gap-0 max-md:grid-cols-1">
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sign Up - ResumeBeen | Start Building Your Professional Resume Today</title>
          <link rel="canonical" href="https://resumebeen.com/signup/" />
          <meta property="og:site_name" content="ResumeBeen"></meta>
          <meta property="og:url" content="https://resumebeen.com/signup/"></meta>
          <meta property="og:locale" content="en_EN"></meta>

          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content="@resumebeen"></meta>
          <meta name="twitter:creator" content="@resumebeen"></meta>

          <meta property="og:type" content="website"></meta>

          <meta name="title" content="Sign Up - ResumeBeen | Start Building Your Professional Resume Today"></meta>
          <meta property="og:title" content="Sign Up - ResumeBeen | Start Building Your Professional Resume Today"></meta>


          <meta name="description" content="Sign up for ResumeBeen and start building your professional resume today. Access AI-powered tools, personalized templates, and expert resume customization to boost your career and land your dream job faster."></meta>
          <meta property="og:description" content="Sign up for ResumeBeen and start building your professional resume today. Access AI-powered tools, personalized templates, and expert resume customization to boost your career and land your dream job faster."></meta>

          <meta name="keywords" content="ResumeBeen sign up, create account, register for ResumeBeen, resume builder sign up, join ResumeBeen, professional resume tools, AI resume builder, start building resume, resume account creation"></meta>
          <meta property="og:keywords" content="ResumeBeen sign up, create account, register for ResumeBeen, resume builder sign up, join ResumeBeen, professional resume tools, AI resume builder, start building resume, resume account creation"></meta>

        </Helmet>
      </>


      <div className="relative w-full h-full bg-conic-gradient max-md:hidden">
        <div
          className="flex gap-2.5 self-start p-6"
          onClick={() => navigate("/")}
        >
          <img
            loading="lazy"
            alt="R"
            src={R}
            className="shrink-1 aspect-[0.75] w-[29px]"
          />
          <img
            loading="lazy"
            src={resumeBeen}
            alt="ResumeBeen"
            className="shrink-0 my-auto max-w-full aspect-[5.56] w-[146px]"
          />
        </div>
        <div className="absolute top-20 left-6 text-5xl font-bold text-white leading-[70px] max-md:top-10 max-md:left-4 max-md:text-4xl max-md:leading-[68px]">
          Build your successful resume instantly using AI
        </div>
        <div className="absolute bottom-0 left-0 flex justify-between w-full p-6 max-md:hidden">
          <img loading="lazy" alt="Resume" src={Group2} className="w-72" />
          <img loading="lazy" alt="Resume" src={Group3} className="w-72" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center p-4 rounded">
        <div className="space-y-4">
          <div className="">
            <div className="text-4xl font-semibold leading-10 tracking-tighter text-gray-900 max-md:mt-4 max-md:max-w-full">
              Sign Up
            </div>
            <div className="mt-3 text-slate-600 max-md:max-w-full">
              Register to Access Exclusive Features
            </div>
          </div>

          <div className="flex flex-col justify-start space-y-2 ">
            <div
              onClick={() => {
                setShowTC(true)
                setShowTCOAuth(true)
              }}
              className="w-full flex gap-3 items-center justify-center py-2.5 hover:cursor-pointer px-6 font-semibold bg-white rounded-lg border border-gray-300 border-solid shadow-sm text-slate-700 max-md:flex-wrap max-md:px-5"
            >
              <img
                loading="lazy"
                src={google}
                className="w-6 shrink-0 aspect-square"
                alt="Google"
              />
              <div>Sign up with Google</div>
            </div>

            <div className="w-full">
              <div className="grid grid-cols-1 grid-rows-1 md:grid-cols-2 md:grid-rows-2 md:gap-2">
                <div>
                  <label
                    htmlFor="firstName"
                    className="mb-2 text-base font-medium "
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    required
                    className={`w-full px-3 py-1 text-sm border rounded-md outline-none ${!errMsg?.firstName ? "" : "border-red-500"
                      }`}
                    name="name"
                    placeholder="Enter your name"
                    value={signUp.firstName}
                    onChange={(e) =>
                      setSignUp((pre) => ({
                        ...pre,
                        firstName: e.target.value,
                      }))
                    }
                  />
                  <p className="mt-2 text-xs text-red-500">
                    {errMsg?.firstName && (
                      <span className="">{errMsg?.firstName}</span>
                    )}
                  </p>
                </div>

                <div>
                  <label for="email" className="mb-2 text-base font-medium ">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    required
                    className={`w-full px-3 py-1 text-sm border rounded-md outline-none ${!errMsg?.email ? "" : "border-red-500"
                      }`}
                    name="email"
                    placeholder="name@example.com"
                    value={signUp.email}
                    onChange={handleEmailChange}
                  />
                  <p className="mt-2 text-xs text-red-500">
                    {errMsg?.email && <span className="">{errMsg?.email}</span>}
                  </p>
                </div>

                <div>
                  <label htmlFor="password" className="mb-2 font-medium">
                    Password
                  </label>
                  <div className="flex flex-wrap w-full px-3 py-1 border rounded-md">
                    <input
                      type={visible ? "text" : "password"}
                      id="password"
                      required
                      className={`flex-grow text-sm outline-none ${!errMsg?.password ? "" : "border-red-500"
                        }`}
                      name="password"
                      placeholder="password ..."
                      value={signUp.password}
                      onChange={handlePasswordChange}
                    />
                    <div
                      onClick={() => setVisible(!visible)}
                      className="float-left cursor-pointer"
                    >
                      {visible ? (
                        <img src={view} alt="Hide" className="w-4 h-4" />
                      ) : (
                        <img src={hide} alt="View" className="w-4 h-4" />
                      )}
                    </div>
                  </div>
                </div>


                <div>
                  <label htmlFor="confirmPassword" className="mb-2 font-medium">
                    Confirm Password
                  </label>
                  <div className="flex flex-wrap w-full px-3 py-1 border rounded-md">
                    <input
                      type={visible1 ? "text" : "password"}
                      id="confirmPassword"
                      required
                      className={`flex-grow text-sm outline-none ${!errMsg?.confirmPassword ? "" : "border-red-500"
                        }`}
                      name="confirmPassword"
                      placeholder="Confirm password ..."
                      value={signUp.confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <div
                      onClick={() => setVisible1(!visible1)}
                      className="float-left cursor-pointer"
                    >
                      {visible1 ? (
                        <img src={view} alt="Hide" className="w-4 h-4" />
                      ) : (
                        <img src={hide} alt="View" className="w-4 h-4" />
                      )}
                    </div>
                  </div>
                  <p className="mt-2 text-xs text-red-500">
                    {errMsg?.confirmPassword && (
                      <span className="">{errMsg?.confirmPassword}</span>
                    )}
                  </p>
                </div>
              </div>

              <div className="flex w-full">
                <CustomButton
                  onClick={handleSubmit}
                  className="py-1 mt-4"
                >
                  Register
                </CustomButton>
              </div>
            </div>
            <p className="mt-1 text-base">
              Already have an account ?{" "}
              <Link className="font-semibold text-violet-700" to={"/login"}>
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
      {showTC && (
        <div
          className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${showTC ? "block" : "block"
            }`}
        >
          <div className="bg-white p-5 rounded-lg max-w-5xl max-h-[80%] flex flex-col shadow-md relative mx-auto">

            <div className="flex justify-between">
              <h2 className="absolute top-2.5 left-6 bg-transparent border-0 text-xl font-bold cursor-pointer">
                Terms & Conditions / Privacy Policy
              </h2>
              <button
                className="absolute top-2.5 right-4 bg-transparent border-0 text-2xl font-bold cursor-pointer"
                onClick={() => setShowTC(false)}
              >
                &times;
              </button>
            </div>

            <div
              className="flex-grow overflow-y-auto"
              ref={termsAndConditionsRef}
              onScroll={handleScroll}
            >
              <div className="" dangerouslySetInnerHTML={{ __html: tc }} />
              <div className="" dangerouslySetInnerHTML={{ __html: pp }} />
            </div>

            <div className="flex w-full">
              <CustomButton
                disabled={!isButtonEnabled} // Button enabled/disabled state
                onClick={handleSignUp}
                loading={loading}
                className="py-1 mt-4"
              >
                Accept Terms and Conditions
              </CustomButton>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Signup;

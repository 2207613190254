import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import ChangePassword from "./Pages/ChangePassword";
// import EmailVerification from "./Pages/EmailVerification";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
// import ResetPasswordConfirm from "./Pages/ResetPasswordConfirm";
import ThankYouPage from "./Pages/ThankYouPage";
import Signup from "./Pages/Signup";
import Layout from "./High Order Function/Layout";
import Template from "./Pages/Template";
import Dashboard from "./Pages/Dashboard";
// import ResumeContext from './Context/ResumeContext';
import Landp1 from "./Pages/landing/landp1"
import Landp2 from "./Pages/landing/landp2"
import Landp3 from "./Pages/landing/landp3"
import Landp4 from "./Pages/landing/landp4"
import Profile from "./Pages/Profile";
import Pricing from "./Pages/Pricing";
import TermsAndConditions from "./Pages/TermsAndConditions";
import AboutUs from "./Pages/AboutUs";
import Contactus from "./Pages/Contactus";
import "./css/main.css";
import CreateResume from "./Pages/CreateResume";
import CreateResumeForJD from "./Pages/CreateResumeForJD";
import Privacypage from "./Pages/Privacypage";
import Checkout from "./Pages/Checkout";
import Payments from "./Pages/Payments";
import { ToastContainer } from 'react-toastify';
import VerifyEmail from "./Pages/VerifyEmail";
import ProtectedRoute from "./High Order Function/ProtectedRoutes";
import ResetPassword from "./Pages/ResetPassword";
import AdminLogin from "./Component/modules/login";
import AdminHome from "./Component/modules/admin";
import AdminProtectedRoute from "./High Order Function/AdminProtectedRout";
import UpdateEmail from "./Pages/update-email";


const App = () => {
  // const resumeContextValue = {
  //   currentTheme: 'Theme1',
  //   showComponent: false,
  //   themeData: {},
  //   componentRef: null
  // };

  return (
    // <ResumeContext.Provider value={resumeContextValue}>
    // <Provider store={Store}>
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lp/build-ats-friendly-resume" element={<Landp1 />} />
          <Route path="lp/ai-optimized-resume" element={<Landp2 />} />
          <Route path="/lp/ai-powered-resume-builder" element={<Landp3 />} />
          <Route path="lp/ai-professional-resume-builder" element={<Landp4 />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          {/* <Route path="change/password" element={<ChangePassword />} /> */}
          <Route path="profile" element={<ProtectedRoute element={<Profile />} />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          {/* <Route path="reset/password/confirm/:uid/:token" element={<ResetPasswordConfirm />} /> */}
          <Route path="dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<Contactus />} />
          <Route path="privacy" element={<Privacypage />} />
          <Route path="create-resume" element={<ProtectedRoute element={<CreateResume />} />} />
          <Route path="create-resume-forjd" element={<ProtectedRoute element={<CreateResumeForJD />} />} />
          <Route path="template" element={<ProtectedRoute element={<Template />} />} />
          <Route path="checkout" element={<ProtectedRoute element={<Checkout />} />} />
          <Route path="payments" element={<ProtectedRoute element={<Payments />} />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route path="update-email" element={<UpdateEmail />} />
          <Route path="admin/login" element={<AdminLogin />} />
          {/* <Route path="admin" element={<AdminHome />}  /> */}
          <Route path="admin" element={<AdminProtectedRoute element={<AdminHome />} />} />
        </Routes>
      </Layout>
      <ToastContainer style={{ zIndex: 10000000 }} />
    </Router>
    // </Provider>
    // {/* </ResumeContext.Provider> */}
  );
}

export default App;

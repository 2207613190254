import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menuss from "../image/icons/align-left.svg";
import { BiHomeAlt } from "react-icons/bi";
import { LiaPhoneSolid } from "react-icons/lia";
import { LuBadgeInfo } from "react-icons/lu";
import { TbLayoutDashboard } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { MdOutlinePayment, MdOutlinePrivacyTip } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import Employee from "../image/Employee.png";
import { API_URL } from "../apiConfig";
// import Coin from "../image/ethereum.png";
import { toast } from "react-toastify";
import axios from "axios";
import userStore from "../store/user.zustand.js";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { FaAngleLeft } from "react-icons/fa";
import { LuFileSpreadsheet } from "react-icons/lu";
import resumeBeen from "../image/resumebeen.svg";
import resumeBeenWhite from "../image/favicons/resumeBeen-white.svg";
import RWhite from "../image/favicons/R-white.svg";
import { IoIosLogIn } from "react-icons/io";
import { PiSuitcaseLight } from "react-icons/pi";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import useTokenConfigStore from "../store/token.zustand.js";

const NAVBARITEMS = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Pricing",
    path: "/pricing",
  },
  {
    title: "Contact",
    path: "/contact-us",
  },
  {
    title: "About us",
    path: "/about-us",
  },
];

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menusOpen, setMenusOpen] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const navigate = useNavigate();
  const menusRef = useRef(null);
  const menuRef = useRef(null);
  // const  userStore((state) => state.user);
  const {isAuthenticated,user,setAuthenticated,clearUser} = userStore()
  const {tokenConfig} = useTokenConfigStore()
  const userLoggedIn = isAuthenticated;
  const [isNearRightEdge, setIsNearRightEdge] = useState(false);
  const tokenContainerRef = useRef(null);
  const location = useLocation();
  const [hovered, setHovered] = useState(false);

  const handleMenusClickOutside = (event) => {
    if (menusRef.current && !menusRef.current.contains(event.target)) {
      setMenusOpen(false);
    } else if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menusOpen || menuOpen) {
      document.addEventListener("mousedown", handleMenusClickOutside);
    } else {
      document.removeEventListener("mousedown", handleMenusClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleMenusClickOutside);
    };
  }, [menusOpen, menuOpen]);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleMenus = () => setMenusOpen(!menusOpen);

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/users/logout`,
        {},
        { withCredentials: true }
      );
      if (response?.data?.success) {
        navigate("/");
        clearUser()
        setAuthenticated(false)
        // userStore.getState().setUser({ user: null, isAuthenticated: false });
      } else {
        toast.error(response?.data?.message, { autoClose: 2500 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 2500 });
    }
  };

  const checkIfNearRightEdge = () => {
    const element = document.querySelector(".token-container");
    if (element) {
      const rect = element.getBoundingClientRect();
      setIsNearRightEdge(rect.right > window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkIfNearRightEdge);
    checkIfNearRightEdge();
    return () => window.removeEventListener("resize", checkIfNearRightEdge);
  }, []);

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  useEffect(() => {
    setShowDropdown(() => {
      if (["/about-us", "/contact-us"].includes(location.pathname)) {
        return true;
      }
    });
  }, []);

  return (
    <div className="sticky top-0 bg-white w-full z-[9999999] border-b-0 border-gray-200">
      <div className="flex items-center justify-between px-8 py-3 bg-white text-slate-700">
        <div className="flex items-center justify-between space-x-20 lg:ml-12">
          <div className="flex justify-between space-x-4">
            {location.pathname === "/Template" ? (
              <>
                <img src={resumeBeen} className="w-40 hover:cursor-pointer" alt="Logo" />
                <div
                  className="flex items-center w-full cursor-pointer"
                  onClick={() => navigate("/dashboard")}  aria-label="Go to Dashboard page"
                >
                  <FaAngleLeft className="h-full p-1 " />
                  <span className="font-medium text-md">Dashboard</span>
                </div>
              </>
            ) : (
              <>
                <p onClick={() => navigate("/")}   aria-label="Go to Home page" className="flex items-center">
                  <img src={resumeBeen} className="w-40" alt="Logo" />
                </p>
              </>
            )}
          </div>

          <div className="justify-between hidden space-x-12 md:flex">
            {location.pathname !== "/Template" &&
              NAVBARITEMS.map((el) => {
                return (
                  <div 
                    key={el.path}
                    onClick={() => navigate(`${el?.path}`)}
                    className={`flex text-base font-archivo text-[#535353]  font-semibold hover:text-slate-900 hover:cursor-pointer ${
                      location.pathname === el.path ? "text-slate-900" : ""
                    }`}
                  >
                    {el.title}
                  </div>
                );
              })}
          </div>
        </div>

        {/* Medium and Large */}
        <div className="items-center hidden gap-3 md:flex">
          {userLoggedIn ? (
            <div className="flex items-center gap-3">
              {/* Right Side - Total Credits */}
              <div className="relative">
                {/* Total Credit Circle */}
                <div
                  className="flex items-center justify-center w-16 h-16 text-white bg-white rounded-full border-2 border-[#004E65] cursor-pointer"
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  <div className="flex flex-col items-center justify-center rounded-full bg-[#004E65] w-[56px] h-[56px] border-5 border-[#003E4C]">
                    <p className="text-base font-bold font-montserrat">
                      {" "}
                      {user?.tokenDetails?.tokens}
                    </p>
                    <p className="bg-[#FF9220] h-[2px] w-10"></p>
                    <p className="text-[8px] font-medium font-montserrat">
                      CREDITS
                    </p>
                  </div>
                </div>

                {/* Animated Credit Breakdown */}
                <div
                  className={`absolute flex justify-center items-center top-full right-0 transform transition-all duration-300 ease-in-out ${
                    hovered ? "opacity-100 scale-100" : "opacity-0 scale-0"
                  } origin-top-right`}
                >
                  <div
                    className="relative flex flex-wrap items-center justify-center space-y-2 top-20"
                    style={{ width: "220px", height: "150px" }}
                  >
                    <div className="flex items-center justify-center w-24 h-24 text-white bg-white rounded-full border-2 border-[#004E65] cursor-pointer">
                      <div className="flex flex-col items-center justify-center rounded-full bg-[#004E65] w-[85px] h-[85px] border-5 border-[#003E4C]">
                        <p className="text-[12px] font-medium text-center text-white font-montserrat leading-[18px]">
                          TOTAL {/* Credits */}
                        </p>
                        <p className="font-semibold font-montserrat text-[20px] leading-[22px]">
                          {" "}
                          {user?.tokenDetails?.createResume +
                            user?.tokenDetails?.profile +
                            user?.tokenDetails?.project +
                            user?.tokenDetails?.workExperience +
                            user?.tokenDetails?.tokens}
                        </p>
                        <p className="bg-[#FF9220] h-[3px] w-14"></p>
                        <p className="text-[12px] text-center font-medium font-montserrat leading-[20px]">
                          CREDITS
                        </p>
                      </div>
                    </div>
                  </div>
                    <div
                      className="relative flex flex-wrap items-center justify-center space-y-2"
                      style={{ width: "220px", height: "150px" }}
                    >
                      {/* Create Resume */}
                    <div
                      className={`absolute left-0 top-0 flex bg-[#F2F2F2] w-52 h-12 rounded-full items-center transition delay-100 duration-500 ease-out ${
                        hovered
                          ? "opacity-100 translate-x-0"
                          : "opacity-0 -translate-x-5"
                      } overflow-visible`}
                    >
                      <div className="relative flex items-center justify-center w-16 h-16 rounded-full border-2 border-[#00BDD6]">
                        <div className="flex items-center justify-center w-14 h-14 border-5 border-[#009EAA] bg-[#00BDD6] rounded-full">
                          <p className="text-lg text-[#FFFFFF] font-archivo font-semibold">
                            {user?.tokenDetails?.createResume}
                          </p>
                        </div>
                      </div>

                      <p className="ml-3 text-[#000000] font-archivo font-medium text-sm">
                        Create Resume - {tokenConfig?.createAIResume}
                      </p>
                    </div>

                    {/* Project */}
                    <div
                      className={`absolute bg-[#F2F2F2] w-52 h-12 rounded-full left-3 top-14 flex items-center transition delay-200 duration-500 ease-out ${
                        hovered
                          ? "opacity-100 translate-x-0"
                          : "opacity-0 translate-x-5"
                      }overflow-visible`}
                    >
                      <div className="relative flex items-center justify-center w-16 h-16 border-2 border-[#FF9220] rounded-full">
                        <div className="flex items-center justify-center bg-[#FF9220] border-[#EA781B] border-5 rounded-full border-5 w-14 h-14">
                          <p className="text-lg text-[#FFFFFF] font-archivo font-semibold">
                            {user?.tokenDetails?.project}
                          </p>
                        </div>
                      </div>

                      <p className="ml-2 text-[#000000] font-archivo font-medium text-sm">
                        Project - {tokenConfig?.project}
                      </p>
                    </div>

                    {/* Profile */}
                    <div
                      className={`absolute bg-[#F2F2F2] w-52 h-12 rounded-full left-6 top-[124px] flex items-center transition delay-300 duration-500 ease-out ${
                        hovered
                          ? "opacity-100 translate-x-0"
                          : "opacity-0 translate-x-5"
                      } overflow-visible`}
                    >
                      <div className="relative flex items-center justify-center w-16 h-16 border-2 border-[#FF415E] rounded-full">
                        <div className="flex items-center justify-center w-14 h-14 border-5 bg-[#FF415E] border-[#BC3558] rounded-full">
                          <p className="text-lg text-[#FFFFFF] font-archivo font-semibold">
                            {user?.tokenDetails?.profile}
                          </p>
                        </div>
                      </div>

                      <p className="ml-2 text-[#000000] font-archivo font-medium text-sm">
                        Profile - {tokenConfig?.profile}
                      </p>
                    </div>

                    {/* Workex */}
                    <div
                      className={`absolute bg-[#F2F2F2] w-52 h-12 rounded-full left-3 top-48 flex items-center transition delay-400 duration-500 ease-out ${
                        hovered
                          ? "opacity-100 translate-x-0"
                          : "opacity-0 -translate-x-5"
                      }overflow-visible`}
                    >
                      <div className="relative flex items-center justify-center w-16 h-16 border-2 border-[#005F98] rounded-full">
                        <div className="flex items-center justify-center bg-[#005F98] border-[#00537C] rounded-full border-5 w-14 h-14">
                          <p className="text-lg text-[#FFFFFF] font-archivo font-semibold">
                            {user?.tokenDetails?.workExperience}
                          </p>
                        </div>
                      </div>

                      <p className="ml-2 text-[#000000] font-archivo font-medium text-sm">
                        Workex - {tokenConfig?.workExperience}
                      </p>
                    </div>

                    {/* Remaining Token */}
                    <div
                      className={`absolute bg-[#F2F2F2] w-52 h-12 rounded-full top-64 left-0 flex items-center transition delay-500 duration-500 ease-out ${
                        hovered ? "opacity-100 translate-y-0" : "opacity-0"
                      } overflow-visible`}
                    >
                      <div className="relative flex items-center justify-center w-16 h-16 border-[#FFB600] border-2 rounded-full">
                        <div className="flex items-center justify-center border-5 w-14 h-14 bg-[#FFB600] border-[#EDA400] rounded-full">
                          <p className="text-lg text-[#FFFFFF] font-archivo font-semibold">
                            {user?.tokenDetails?.tokens ?? 0}
                          </p>
                        </div>
                      </div>

                      <p className="ml-2 text-[#000000] font-archivo font-medium text-sm">
                        Remaining Token
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative">
                <img
                  src={user?.profilePic || Employee}
                  alt={Employee}
                  className="border rounded-full cursor-pointer h-14 w-14"
                  onClick={toggleMenus}
                />

                {menusOpen && (
                  <div
                    className="absolute right-0 z-10 w-48 mt-2 bg-white border border-gray-300 rounded-md shadow-lg"
                    ref={menusRef}
                  >
                    <p
                      onClick={() => navigate("/dashboard")}  aria-label="Go to Dashboard page"
                      className="block px-4 py-2 text-gray-800 hover:cursor-pointer hover:bg-gray-200"
                    >
                      Dashboard
                    </p>
                    <p
                      onClick={() => navigate("/profile")}  aria-label="Go to Profile page"
                      className="block px-4 py-2 text-gray-800 hover:cursor-pointer hover:bg-gray-200"
                    >
                      Profile
                    </p>
                    <p
                      onClick={() => navigate("/payments")}  aria-label="Go to Payments page"
                      className="block px-4 py-2 text-gray-800 hover:cursor-pointer hover:bg-gray-200"
                    >
                      Payments
                    </p>
                    {userLoggedIn && (
                      <p
                        onClick={handleLogout}
                        className="block px-4 py-2 text-gray-800 hover:cursor-pointer hover:bg-gray-200"
                      >
                        Logout
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="flex w-[176px] h-[48px] justify-between hover:cursor-pointer text-[#ffffff] border-1 border-[#1F6463] shadow-news-letter hover:border-[#929292] hover:bg-[#FFFFFF] hover:text-[#929292]  items-center rounded-[3px] space-x-2 px-3 py-2 bg-[#1F6463]"
              onClick={() => navigate("/login")}  aria-label="Go to Login page"
            >
              <LuFileSpreadsheet size="20" />
              <button className="text-base font-normal leading-4 font-archivo">
                Create Resume
              </button>
            </div>
          )}
        </div>

        {/* Mobile view */}
        <div className="flex items-center gap-3 md:hidden">
          {userLoggedIn && (
            <div className="relative">
              {/* Total Credit Circle */}
              <div
                className="flex items-center justify-center w-16 h-16 text-white bg-white rounded-full border-2 border-[#004E65] cursor-pointer"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <div className="flex flex-col items-center justify-center rounded-full bg-[#004E65] w-14 h-14 border-2 border-[#003E4C]">
                  <p className="text-[8px] font-medium text-center text-white font-montserrat">
                    {/* TOTAL{" "} */}
                  </p>
                  <p className="text-sm font-semibold font-montserrat">
                    {user?.tokenDetails?.tokens}
                  </p>
                  <p className="bg-[#FF9220] h-[2px] w-10"></p>
                  <p className="text-[8px] font-medium font-montserrat">
                    CREDITS
                  </p>
                </div>
              </div>

              {/* Animated Credit Breakdown */}
              <div
                className={`absolute flex justify-center items-center top-full -right-12 transform transition-all duration-300 ease-in-out ${
                  hovered ? "opacity-100 scale-100" : "opacity-0 scale-0"
                } origin-top-right`}
              >
                <div
                  className="relative flex flex-wrap items-center justify-center space-y-2 top-20 left-5"
                  style={{ width: "220px", height: "150px" }}
                >
                  <div className="flex items-center justify-center w-20 h-20 text-white bg-white rounded-full border-2 border-[#004E65] cursor-pointer">
                    <div className="flex flex-col items-center justify-center rounded-full bg-[#004E65] w-[70px] h-[70px] border-5 border-[#003E4C]">
                      <p className="text-[10px] font-medium text-center text-white font-montserrat leading-[18px]">
                        TOTAL {/* Credits */}
                      </p>
                      <p className="font-semibold font-montserrat text-[18px] leading-[22px]">
                        {" "}
                        {user?.tokenDetails?.createResume +
                          user?.tokenDetails?.profile +
                          user?.tokenDetails?.project +
                          user?.tokenDetails?.workExperience +
                          user?.tokenDetails?.tokens}
                      </p>
                      <p className="bg-[#FF9220] h-[2px] w-12">.</p>
                      <p className="text-[10px] text-center font-medium font-montserrat leading-[20px]">
                        CREDITS
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="relative flex flex-wrap items-center justify-center space-y-2 sm:space-y-4"
                  style={{ width: "220px", height: "150px" }}
                >
                  {/* Create Resume */}
                  <div
                    className={`absolute left-0 top-0 flex bg-[#F2F2F2] w-52 h-12  rounded-full items-center transition delay-100 duration-500 ease-out ${
                      hovered
                        ? "opacity-100 translate-x-0"
                        : "opacity-0 -translate-x-5"
                    } sm:flex-col overflow-visible`}
                  >
                    <div className="relative flex items-center justify-center w-16 h-16 rounded-full border-2 border-[#009EAA] sm:w-10 sm:h-10">
                      <div className="flex items-center justify-center w-14 h-14 border-[#009EAA] border-5 bg-[#00BDD6] rounded-full">
                        <p className="text-lg text-[#FFFFFF] font-archivo font-semibold sm:text-base">
                          {user?.tokenDetails?.createResume}
                        </p>
                      </div>
                    </div>

                    <p className="ml-3 text-[#000000] font-archivo font-medium text-sm sm:text-xs">
                      Create Resume - {tokenConfig?.createAIResume}
                    </p>
                  </div>

                  {/* Project */}
                  <div
                    className={`absolute left-3 top-14 flex bg-[#F2F2F2] w-52 h-12 rounded-full items-center transition delay-200 duration-500 ease-out ${
                      hovered
                        ? "opacity-100 translate-x-0"
                        : "opacity-0 translate-x-5"
                    } sm:flex-col sm:items-center overflow-visible`}
                  >
                    <div className="relative flex items-center justify-center w-16 h-16 border-2 border-[#FF9220] rounded-full sm:w-10 sm:h-10">
                      <div className="flex items-center justify-center w-14 h-14 bg-[#FF9220] border-[#EA781B] border-5 rounded-full">
                        <p className="text-lg text-[#FFFFFF] font-archivo font-semibold sm:text-base">
                          {user?.tokenDetails?.project}
                        </p>
                      </div>
                    </div>

                    <p className="ml-2 text-[#000000] font-archivo font-medium text-sm sm:text-xs">
                      Project - {tokenConfig?.project}
                    </p>
                  </div>

                  {/* Profile */}
                  <div
                    className={`flex absolute left-6 top-[124px] bg-[#F2F2F2] w-52 h-12 rounded-full items-center transition delay-300 duration-500 ease-out ${
                      hovered
                        ? "opacity-100 translate-x-0"
                        : "opacity-0 translate-x-5"
                    } sm:flex-col sm:items-center overflow-visible`}
                  >
                    <div className="relative flex items-center justify-center w-16 h-16 border-2 border-[#FF415E] rounded-full sm:w-10 sm:h-10">
                      <div className="flex items-center justify-center w-14 h-14 border-5 bg-[#FF415E] border-[#BC3558] rounded-full">
                        <p className="text-lg text-[#FFFFFF] font-archivo font-semibold sm:text-base">
                          {user?.tokenDetails?.profile}
                        </p>
                      </div>
                    </div>

                    <p className="ml-2 text-[#000000] font-archivo font-medium text-sm sm:text-xs">
                      Profile - {tokenConfig?.profile}
                    </p>
                  </div>

                  {/* Workex */}
                  <div
                    className={`absolute left-3 top-48 flex bg-[#F2F2F2] w-52 h-12 rounded-full items-center transition delay-400 duration-500 ease-out ${
                      hovered
                        ? "opacity-100 translate-x-0"
                        : "opacity-0 -translate-x-5"
                    } sm:flex-col sm:items-center overflow-visible`}
                  >
                    <div className="relative flex items-center justify-center w-16 h-16 border-2 border-[#005F98] rounded-full sm:w-10 sm:h-10">
                      <div className="flex items-center justify-center bg-[#005F98] border-[#00537C] rounded-full border-5 w-14 h-14">
                        <p className="text-lg text-[#FFFFFF] font-archivo font-semibold sm:text-base">
                          {user?.tokenDetails?.workExperience}
                        </p>
                      </div>
                    </div>

                    <p className="ml-2 text-[#000000] font-archivo font-medium text-sm sm:text-xs">
                      Workex - {tokenConfig?.workExperience}
                    </p>
                  </div>

                  {/* Remaining Token */}
                  <div
                    className={`flex absolute left-0 top-64 bg-[#F2F2F2] w-52 h-12 rounded-full items-center transition delay-500 duration-500 ease-out ${
                      hovered ? "opacity-100 translate-y-0" : "opacity-0"
                    } sm:flex-col sm:items-center overflow-visible`}
                  >
                    <div className="relative flex items-center justify-center w-16 h-16 border-[#FFB600] border-2 rounded-full sm:w-12 sm:h-12">
                      <div className="flex items-center justify-center border-5 w-14 h-14 bg-[#FFB600] border-[#EDA400] rounded-full">
                        <p className="text-lg text-[#FFFFFF] font-archivo font-semibold sm:text-base">
                          {user?.tokenDetails?.tokens ?? 0}
                        </p>
                      </div>
                    </div>

                    <p className="ml-2 text-[#000000] font-archivo font-medium text-sm sm:text-xs">
                      Remaining Token
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <img
            src={menuss}
            alt="Menu"
            className="cursor-pointer"
            onClick={toggleMenu}
          />
          {menuOpen && (
            <div
              ref={menuRef}
              className={`fixed ${
                userLoggedIn ? "mt-[95px]" : "mt-[60px]"
              } h-full top-0 bottom-0 left-0 z-50 w-4/6 p-6 bg-[#1E6463] text-[#F4EBFF] shadow-lg md:hidden`}
            >
              <div className="flex items-end justify-start space-x-2">
                <img src={RWhite} alt="R" className="w-[24px]" />
                <img
                  src={resumeBeenWhite}
                  alt="resumeBeen"
                  className="w-[140px]"
                />
              </div>
              <div className="flex flex-col items-start mt-10 gap-y-3">
                <div className="flex flex-col justify-between w-full">
                  <div className="flex flex-col items-start gap-y-1">
                    <p
                      className={`w-full text-lg flex justify-between ${
                        location.pathname === "/" ? "bg-[#EE702E]" : ""
                      } p-1 rounded-lg font-light hover:cursor-pointer`}
                    >
                      <span className="flex" onClick={() => navigate("/")}   aria-label="Go to Home page">
                        <BiHomeAlt className="inline-block mr-2" size={28} />
                        Home
                      </span>
                      {/* Toggle arrow */}
                      <span
                        onClick={toggleDropdown}
                        className="hover:cursor-pointer"
                      >
                        {showDropdown ? (
                          <FaChevronDown size={24} />
                        ) : (
                          <FaChevronUp size={24} />
                        )}
                      </span>
                    </p>

                    {showDropdown && (
                      <div
                        className={`flex flex-col gap-y-1 pl-8 w-full transform transition-all duration-500 ease-in-out overflow-hidden ${
                          showDropdown ? "max-h-[500px]" : "max-h-0"
                        }`}
                      >
                        <p
                          onClick={() => navigate("/contact-us")}  aria-label="Go to Contact Us page"
                          className={`w-full text-lg flex p-1 rounded-lg font-light hover:cursor-pointer ${
                            location.pathname === "/contact-us"
                              ? "bg-[#EE702E]"
                              : ""
                          } `}
                        >
                          Contact
                        </p>
                        <p
                          onClick={() => navigate("/about-us")}  aria-label="Go to About Us page"
                          className={`w-full text-lg flex p-1 rounded-lg font-light hover:cursor-pointer ${
                            location.pathname === "/about-us"
                              ? "bg-[#EE702E]"
                              : ""
                          } `}
                        >
                          About
                        </p>
                      </div>
                    )}

                    {userLoggedIn && (
                      <>
                        <p
                          onClick={() => navigate("/dashboard")}  aria-label="Go to Dashboard page"
                          className={`w-full text-lg flex  ${
                            location.pathname === "/dashboard"
                              ? "bg-[#EE702E]"
                              : ""
                          } p-1 rounded-lg font-light hover:cursor-pointer`}
                        >
                          <TbLayoutDashboard
                            className="inline-block mr-2"
                            size={28}
                          />
                          Dashboard
                        </p>
                        <p
                          onClick={() => navigate("/profile")}  aria-label="Go to Profile page"
                          className={`w-full text-lg flex  ${
                            location.pathname === "/profile"
                              ? "bg-[#EE702E]"
                              : ""
                          } p-1 rounded-lg font-light hover:cursor-pointer`}
                        >
                          <CgProfile className="inline-block mr-2" size={28} />
                          Profile
                        </p>
                        <p
                          onClick={() => navigate("/payments")}  aria-label="Go to Payments page"
                          className={`w-full text-lg flex  ${
                            location.pathname === "/payments"
                              ? "bg-[#EE702E]"
                              : ""
                          } p-1 rounded-lg font-light hover:cursor-pointer`}
                        >
                          <MdOutlinePayment
                            className="inline-block mr-2"
                            size={28}
                          />
                          Payments
                        </p>
                      </>
                    )}

                    <p
                      onClick={() => navigate("/pricing")}  aria-label="Go to Pricing page"
                      className={`w-full text-lg flex  ${
                        location.pathname === "/pricing" ? "bg-[#EE702E]" : ""
                      } p-1 rounded-lg font-light hover:cursor-pointer`}
                    >
                      <MdOutlinePayment
                        className="inline-block mr-2"
                        size={28}
                      />
                      Pricing
                    </p>
                    {!userLoggedIn && (
                      <>
                        <p
                          onClick={() => navigate("/dashboard")} aria-label="Go to Dashboard page"
                          className={`w-full text-lg flex  ${
                            location.pathname === "/login" ? "bg-[#EE702E]" : ""
                          } p-1 rounded-lg font-light hover:cursor-pointer`}
                        >
                          <IoIosLogIn className="inline-block mr-2" size={28} />
                          Login
                        </p>
                        <p
                          onClick={() => navigate("/signup")}  aria-label="Go to Sign Up page"
                          className={`w-full text-lg flex  ${
                            location.pathname === "/profile"
                              ? "bg-[#EE702E]"
                              : ""
                          } p-1 rounded-lg font-light hover:cursor-pointer`}
                        >
                          <PiSuitcaseLight
                            className="inline-block mr-2"
                            size={28}
                          />
                          Register
                        </p>
                      </>
                    )}
                  </div>
                  <div className="flex flex-col items-start mt-10 space-y-4">
                    <div>
                      <p
                        onClick={() => navigate("/terms")}  aria-label="Go to Terms & Conditions page"
                        className={`w-full text-lg flex  ${
                          location.pathname === "/terms" ? "bg-[#EE702E]" : ""
                        } p-1 rounded-lg font-light hover:cursor-pointer`}
                      >
                        <VscWorkspaceTrusted
                          className="inline-block mr-2"
                          size={28}
                        />
                        Tearms & Conditions
                      </p>
                      <p
                        onClick={() => navigate("/privacy")}  aria-label="Go to Privacy page"
                        className={`w-full text-lg flex  ${
                          location.pathname === "/privacy" ? "bg-[#EE702E]" : ""
                        } p-1 rounded-lg font-light hover:cursor-pointer`}
                      >
                        <MdOutlinePrivacyTip
                          className="inline-block mr-2"
                          size={28}
                        />
                        Privacy Policy
                      </p>
                    </div>

                    {userLoggedIn && (
                      <div className="w-full">
                        <div className="flex items-end justify-between">
                          <div className="ml-4">
                            <p className="text-base font-normal">
                              {user?.firstName || ""}{" "}
                              {user?.lastName}
                            </p>
                            <p className="text-sm font-light">{user?.email}</p>
                          </div>
                          <div className="hover:cursor-pointer" onClick={handleLogout}>
                            <IoIosLogOut size={32} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

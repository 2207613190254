import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../Component/navbar";
import Footer from "../Component/footer.jsx";
import SuccessImg from "../image/success.png";
import { API_URL, DEFAULT_CURRENCY, CURRENCY_CONVERSION } from "../apiConfig";

const ThankYouPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const subscriptionId = searchParams.get("subscription_id");
    const status = searchParams.get("status");
    const planId = searchParams.get("plan_id");
    const amount = searchParams.get("amount");
    const plan_name = searchParams.get("plan_name");

    return (
        <div className="flex flex-col min-h-screen">
            {/* Navbar */}
            <Navbar />

            {/* Main Content */}
            <div className="p-0 py-[15px] md:py-20">
            <div className="flex-grow flex items-center justify-center bg-gray-100 p-[15px] md:p-20">
                <div className="p-6 text-center bg-white rounded-lg shadow-lg p-20">
                    {status === "success" ? (
                        <div>
                            <div className="relative flex items-center justify-center w-24 h-24 mx-auto mb-4 rounded-full">
                                <img src={SuccessImg} alt="Success" />
                            </div>
                            <h2 className="mb-2 text-xl font-semibold">Thank You! Payment Complete</h2>
                            <p>Transaction ID: {subscriptionId}</p>
                            <div className="p-4 my-4 text-left bg-gray-100 rounded-lg">
                                <p className="text-sm">Plan: {plan_name}</p>
                                <p className="text-sm">
                                    Amount: {DEFAULT_CURRENCY} ${Math.round(amount / CURRENCY_CONVERSION)}
                                </p>
                            </div>
                            <Helmet>
                                <script>
                                    {`
                  gtag('event', 'conversion', {
                    'send_to': 'AW-943384411/hScLCIvzo-0ZENvO68ED',
                    'value': ${Math.round(amount / CURRENCY_CONVERSION)},
                    'currency': '${DEFAULT_CURRENCY}',
                    'transaction_id': '${subscriptionId}'
                  });
                `}
                                </script>
                            </Helmet>
                            <button
                                className="px-4 py-2 mt-4 text-white bg-teal-800 rounded-lg"
                                onClick={() => navigate("/payments")}
                            >
                                Go to Payments
                            </button>
                        </div>
                    ) : (
                        <div>
                            <h2 className="mb-4 text-xl font-semibold">
                                There was an issue with your payment
                            </h2>
                            <button
                                className="px-4 py-2 text-white bg-gray-800 rounded-lg"
                                onClick={() => navigate("/retry-payment")}
                            >
                                Try Again
                            </button>
                        </div>
                    )}
                </div>
            </div>
            </div>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default ThankYouPage;

import React, { useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../apiConfig";
import CustomButton from "../Reusable/CustomButton";
import { toast } from "react-toastify";
import hirebeen from "../image/hirebeenLogo.png";
import H from "../image/H.png";
import resumeBeen from "../image/ResumeBeen.png";
import R from "../image/R.svg";
import Group2 from "../image/Group2.svg";
import Group3 from "../image/Group3.svg";
import view from "../image/icons/view.png";
import hide from "../image/icons/hide.png";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = searchParams.get("token");

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;

  const handleResetPassword = async () => {
    setErrorMessage(""); // Clear previous error messages
    if (!password || !confirmPassword) {
      setErrorMessage("Please enter both password fields.");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    if (!passwordRegex.test(password)) {
      setErrorMessage(
        "Password must be at least 8 characters long and include both letters and numbers."
      );
      return;
    }

    try {
      setLoading(true);
      const resp = await axios.put(`${API_URL}/api/v1/users/reset-password`, {
        token,
        password,
      });
      setLoading(false);

      if (resp.data.success) {
        toast.success(resp?.data?.message, { autoClose: 2500 });
        setStatus(true);
        // if (status) {
        navigate("/dashboard");
        return <Navigate to="/login" />;
        // }
      } else {
        setErrorMessage(resp.data.message);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response?.data?.message || "An error occurred");
    }
  };

  const handleDisableBtn = () => {
    return (
      !password ||
      !confirmPassword ||
      password !== confirmPassword ||
      !passwordRegex.test(password)
    );
  };

  return (
    <div className="grid h-screen grid-cols-2 gap-0 max-md:grid-cols-1">
      {/* Left Side - Images */}
      <div className="relative w-full h-full bg-conic-gradient max-md:hidden">
        <div
          className="flex gap-2.5 self-start p-6"
          onClick={() => navigate("/")}
        >
          <img
            loading="lazy"
            alt="R"
            src={R}
            className="shrink-1 aspect-[0.75] w-[29px]"
          />
          <img
            loading="lazy"
            src={resumeBeen}
            alt="ResumeBeen"
            className="shrink-0 my-auto max-w-full aspect-[5.56] w-[146px]"
          />
        </div>
        <div className="absolute top-20 left-6 text-5xl font-bold text-white leading-[70px] max-md:top-10 max-md:left-4 max-md:text-4xl max-md:leading-[68px]">
          Build your successful resume instantly using AI
        </div>
        <div className="absolute bottom-0 left-0 flex justify-between w-full p-6 max-md:hidden">
          <img loading="lazy" alt="Resume" src={Group2} className="w-72" />
          <img loading="lazy" alt="Resume" src={Group3} className="w-72" />
        </div>
      </div>

      {/* Right Side - Form */}
      <div className="flex flex-col items-center justify-center h-full max-w-md p-6 mx-auto rounded-lg">
        <div className="w-full">
          <h2 className="mb-4 text-3xl font-semibold text-center text-gray-900">
            Reset Password
          </h2>
          <h5 className="mb-6 text-center text-gray-600">
            Please enter your new password and confirm it. We will update your
            password and redirect you to the login page.
          </h5>
          <form className="w-full">
            <div className="mb-4">
              <label htmlFor="password" className="block mb-2 text-gray-700">
                New Password :
              </label>
              <div className="relative">
                <input
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={passwordVisible ? "text" : "password"}
                  className="w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2"
                  id="password"
                  placeholder="Enter new password"
                />
                <div
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                >
                  <img
                    src={passwordVisible ? view : hide}
                    alt="Toggle visibility"
                    className="w-6 h-6"
                  />
                </div>
              </div>
              {errorMessage && (
                <p className="mt-2 text-xs text-red-500">{errorMessage}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block mb-2 text-gray-700"
              >
                Confirm Password :
              </label>
              <div className="relative">
                <input
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2"
                  id="confirmPassword"
                  placeholder="Confirm new password"
                />
                <div
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                  className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                >
                  <img
                    src={confirmPasswordVisible ? view : hide}
                    alt="Toggle visibility"
                    className="w-6 h-6"
                  />
                </div>
              </div>
              {errorMessage && (
                <p className="mt-2 text-xs text-red-500">{errorMessage}</p>
              )}
            </div>
            <div className="flex justify-center">
              <CustomButton
                onClick={handleResetPassword}
                loading={loading}
                className="px-6 py-2 text-white rounded-md shadow-md focus:outline-none focus:ring-2"
                disabled={handleDisableBtn()}
              >
                Reset Password
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, DEFAULT_CURRENCY, CURRENCY_CONVERSION } from "../apiConfig";
import Spinner from "../image/spinner.svg";
import Navbar from "../Component/navbar.jsx";
import Footer from "../Component/footer.jsx";
// import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import download from "../image/dowload.svg";
// import { PiSmileySadBold } from "react-icons/pi";
import socket from "../High Order Function/socket.js";
import R from '../image/favicons/R.svg'
import {Helmet} from "react-helmet";

function Payments() {
  const navigate = useNavigate();
  const [cur_plan_name, setCurrPlanName] = useState(null);
  const [cur_plan_date, setCurrPlanDate] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState(null);
  const [loader, setLoader] = useState(false);
  const [subStatus, setSubStatus] = useState("");
  const [deactivatePopup, setDeactivatePop] = useState(false);
  const [completedFlag, setCompletedFlag] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");

  const [disableCancelBtn, setDisableCancelBtn] = useState(true);

  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [userSubscription, setUserSubscription] = useState([]);

  const [userSubscriptionPaid, setUserSubscriptionPaid] = useState(false);

  const [cancelSubscriptionLoader, setCancelSubscriptionLoader] =
    useState(false);

  const [currentAtivePlanStatus, setCurrentActivePlanStatus] = useState("");
  const [currentPlanChargeAt, setCurrentPlanChargeAt] = useState("");

  const handleCheckboxChanges = (invoiceURL) => {
    setSelectedInvoice((prevSelected) => {
      if (prevSelected.includes(invoiceURL)) {
        return prevSelected.filter((url) => url !== invoiceURL);
      } else {
        return [...prevSelected, invoiceURL];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedInvoice([]);
    } else {
      setSelectedInvoice(subscriptionDetails.map((item) => item?.invoiceURL));
    }
    setSelectAll(!selectAll);
  };

  const downloadInvoice = async () => {
    // console.log("selectedInvoices:",selectedInvoices)
    selectedInvoice.forEach((url) => {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", url.split("/").pop());
      document.body.appendChild(link);
      link.click();
      link.remove();
      // document.body.removeChild(link);
    });
  };

  const fetchPlans = async () => {
    try {
      const resp = await axios.get(`${API_URL}/api/v1/plan/plans`, {
        withCredentials: true,
      });

      if (!resp?.data?.success) {
        toast.error(resp?.data?.message, {
          autoClose: 2500,
        });
        return;
      }

      const filteredPlans = resp?.data?.data?.plans
        .sort((a, b) => a.plan_price - b.plan_price)
        .filter((plan) => !plan.is_subscribed && plan.plan_type === "Pro");

      setPlans(filteredPlans);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (d) => {
    const timestamp = d;
    const date = new Date(timestamp * 1000);

    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    return formattedDate;
  };

  const userPaymentData = (data) => {
    setUserSubscriptionPaid(data[0]?.status === "paid");
    if (data.length > 0) {
      const subscriptionsCharged = [];

      data.forEach((sub) => {
        setSubStatus(sub.status.toUpperCase());
        if (sub?.status === "active") {
          setSubscriptionId(sub?.subscription_id);
          if (sub.total_count !== sub.paid_count) {
            setCompletedFlag(true);
          }
        }
        const chargedDetail = sub.razorpay_details.find(
          (detail) => detail.event_type === "subscription.charged"
        );
        setCurrPlanName(sub.product_name);
        if (chargedDetail && chargedDetail.invoiceURL) {
          const formattedDate = formatDate(chargedDetail.current_end);
          setCurrPlanDate(formattedDate);
          subscriptionsCharged.push({
            productName: sub.product_name,
            subscriptionChargedDate: formatDate(chargedDetail.event_timestamp),
            amount: chargedDetail.amount,
            invoiceURL: chargedDetail.invoiceURL,
            charge_at: formatDate(chargedDetail.charge_at),
          });
        }
      });

      // SET LATEST SUBSCRIPTION PLAN
      setCurrentActivePlanStatus(data[0]?.status);
      setCurrPlanName(data[0]?.product_name);
      setCurrentPlanChargeAt(
        formatDate(data[0]?.razorpay_details[0]?.charge_at)
      );
      console.log({ chargeAt: data[0]?.razorpay_details[0]?.charge_at });
      setSubscriptionDetails(subscriptionsCharged);
    } else {
      console.error("No subscriptions found for the user");
    }
  };

  const fetchUserSubs = async () => {
    try {
      setLoader(true);

      const response = await axios.get(
        `${API_URL}/api/v1/payment/payment-details`,
        { withCredentials: true }
      );

      if (!response?.data?.success) {
        console.error("No subscriptions found for the user");
      }

      if (response?.data?.success) {
        const data = response?.data?.data;
        // console.log(data
        userPaymentData(data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoader(false);
    }
  };
  // const handleCheckboxChange = (invoiceURL) => {
  //   setSelectedInvoices(invoiceURL === selectedInvoices ? null : invoiceURL);
  // };

  //   const downloadInvoices = async () => {
  //     const zip = new JSZip();

  //     const fetchFile = async (url) => {
  //       const response = await fetch(url);
  //       if (!response.ok) throw new Error(`Could not fetch file: ${url}`);
  //       const blob = await response.blob();
  //       return blob;
  //     };

  //     try {
  //       await Promise.all(selectedInvoices.map(async (url, index) => {
  //         const blob = await fetchFile(url);
  //         const filename = url.split('/').pop();
  //         zip.file(filename, blob);
  //       }));

  //       const content = await zip.generateAsync({ type: 'blob' });
  //       saveAs(content, 'invoices.zip');
  //     } catch (error) {
  //       console.error('Error downloading files:', error);
  //     }
  //   };

  // const downloadInvoices = async () => {
  //   const link = document.createElement("a");
  //   link.href = selectedInvoices;
  //   link.setAttribute("download", selectedInvoices.split("/").pop()); // Extract file name from URL
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleDpop = () => {
    setDeactivatePop(true);
  };

  const handleStaySubscribed = () => {
    setDeactivatePop(false);
  };

  // const fetchUserProfile = async () => {
  //   try {
  //     const authToken = localStorage.getItem("access");

  //     const response = await fetch(`${API_URL}/fetch-user-profile`, {
  //       method: "GET",
  //       headers: {
  //         "X-CSRFToken": CSRFToken(document.cookie),
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });

  //     const data = await response.json();

  //     dispatch({
  //       type: TYPE.GET_USER_SUCCESS,
  //       payload: {
  //         user: data,
  //       },
  //     });
  //     return;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleCancelSubscription = async () => {
    try {
      if (!subscriptionId) {
        toast.error("Invalid subscription ID", {
          autoClose: 1500,
        });
        setLoader(false);
        return;
      }

      setLoader(true);
      setCancelSubscriptionLoader(true);
      const resp = await axios.post(
        `${API_URL}/api/v1/payment/cancel-subscription`,
        {
          subscription_id: subscriptionId,
        },
        { withCredentials: true }
      );
      setDeactivatePop(false);
      setLoader(false);
      setDisableCancelBtn(true);

      if (!resp?.data?.success) {
        toast.error(resp?.data?.message, {
          autoClose: 2500,
        });
        setCancelSubscriptionLoader(false);
        return;
      }

      toast.success(resp?.data?.message, {
        autoClose: 1500,
      });
      await fetchUserSubs();
      setDeactivatePop(false);
      setCancelSubscriptionLoader(false);

      return;
    } catch (error) {
      console.log(error);
      setLoader(false);
      setCompletedFlag(false);
      setDeactivatePop(false);
      setDisableCancelBtn(true);
      setCancelSubscriptionLoader(false);
      toast.error("Failed to cancel subscription", {
        autoClose: 2500,
      });
      return;
    }
  };

  useEffect(() => {
    socket.on("paymentStatusUpdated", (details) => {
      userPaymentData(details);
    });
    // Clean up the socket connection on component unmount
    return () => {
      socket.off("paymentStatusUpdated");
    };
  }, []);

  // useEffect(() => {
  //   fetchUserProfile();
  // }, []);

  useEffect(() => {
    fetchUserSubs();
    fetchPlans();
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Payment - ResumeBeen | Unlock Premium Resume-Building Features</title>
            <link rel="canonical" href="https://resumebeen.com/payments" />
            <meta property="og:site_name" content="ResumeBeen"></meta>
            <meta property="og:url" content="https://resumebeen.com/payments"></meta>
            <meta property="og:locale" content="en_EN"></meta>

            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@resumebeen"></meta>
            <meta name="twitter:creator" content="@resumebeen"></meta>

            <meta property="og:type" content="website"></meta>

            <meta name="title" content="Payment - ResumeBeen | Unlock Premium Resume-Building Features"></meta>
            <meta property="og:title" content="Payment - ResumeBeen | Unlock Premium Resume-Building Features"></meta>


            <meta name="description" content="Complete your payment on ResumeBeen to unlock premium features, including advanced resume customization, Matching Mode Analysis, and personalized templates. Invest in your career with tools that help you stand out and secure your dream job."></meta>
            <meta property="og:description" content="Complete your payment on ResumeBeen to unlock premium features, including advanced resume customization, Matching Mode Analysis, and personalized templates. Invest in your career with tools that help you stand out and secure your dream job."></meta>

            <meta name="keywords" content="ResumeBeen payment, unlock premium features, resume builder subscription, resume customization, Matching Mode Analysis, premium resume tools, professional resume builder, cover letter customization, advanced resume templates, career advancement tools"></meta>
            <meta property="og:keywords" content="ResumeBeen payment, unlock premium features, resume builder subscription, resume customization, Matching Mode Analysis, premium resume tools, professional resume builder, cover letter customization, advanced resume templates, career advancement tools"></meta>
            <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-0RHDXFM2Z9');`}</script>
        </Helmet>      
      </>


      <Navbar />
      <div className="flex flex-col items-center justify-center min-h-screen px-4 py-8">
        {loader && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-10">
            <img
              loading="lazy"
              src={Spinner}
              alt="spinner"
              className="w-12 h-12 animate-spin max-md:w-6 max-md:h-6"
            />
          </div>
        )}

        {/* Confirm active plan cancellation modal */}
        {deactivatePopup && (
          <div className="fixed inset-0 z-[99999991] flex items-center justify-center bg-black bg-opacity-60">
            <div className="flex flex-col w-[420px] h-[380px] rounded-2xl bg-white">
              <h6 className="px-4 pt-3 text-xs font-semibold text-orange-500">
                CANCEL SUBSCRIPTION
              </h6>
              <p className="px-4 pt-2 text-2xl font-bold">
                You’re about to cancel your subscription
              </p>
              <p className="px-4 pt-2 font-semibold text-gray-500">
                After cancelling your hirebeen subscription your premium
                benefits will continue until the subscription end date.
              </p>
              <hr className="mx-4 my-6 text-gray-500" />
              <ul className="mr-4 text-sm">
                <li className="mb-2">Subscription end date is 24/01/2024</li>
                <li className="mr-2">
                  You will not be billed after your subscription end date
                </li>
              </ul>
              <div className="flex mx-4 my-3">
                <input
                  type="checkbox"
                  className="mr-1 mt-[2px]"
                  onClick={() => setDisableCancelBtn((prev) => !prev)}
                />
                I confirm I want to cancel my subscription.
              </div>
              <div className="flex gap-2 mx-4">
                <button
                  className="px-4 py-2 text-sm font-semibold rounded-md flex-3 h-fit border-1"
                  onClick={handleStaySubscribed}
                >
                  Stay Subscribed
                </button>
                <button
                  onClick={handleCancelSubscription}
                  className="flex items-center justify-center flex-1 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md h-fit border-1"
                  disabled={disableCancelBtn || cancelSubscriptionLoader}
                >
                  {cancelSubscriptionLoader ? (
                    <FaSpinner className="text-white animate-spin" size="28" />
                  ) : (
                    <>{"Yes, Cancel Subscription"}</>
                  )}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Plan Active Heading */}
        {/* Plan Active Heading */}
        {["completed", "active"].includes(currentAtivePlanStatus) ? (
          <h6 className="text-center ">
            YOUR CURRENT{" "}
            <span className="font-semibold text-teal-800">{cur_plan_name}</span>{" "}
            SUBSCRIPTON IS{" "}
            <span className="font-semibold text-teal-800">{"ACTIVE"}</span> TILL{" "}
            <span className="text-base font-semibold text-teal-800">
              {cur_plan_date}
            </span>
            <p className="text-sm font-medium text-center text-gray-500 uppercase">
              You will be next charged on {currentPlanChargeAt}
            </p>
          </h6>
        ) : currentAtivePlanStatus === "cancelled" ? (
          <>
            <div className="flex flex-col justify-center text-center">
              <div className="flex flex-col justify-start">
                <h6>
                  Your current{" "}
                  <span className="font-semibold text-teal-800">
                    {cur_plan_name}
                  </span>{" "}
                  plan has been cancelled.
                </h6>
                <h6>You won't be charged for any future subscriptions</h6>
                <h6>
                  But it is active until{" "}
                  <span className="font-semibold">{currentPlanChargeAt}</span>.
                </h6>
              </div>
            </div>
          </>
        ) : (["intiated", "created", "paid"].includes(currentAtivePlanStatus) && !["abandoned"].includes(currentAtivePlanStatus)) ? (
          <div className="flex justify-center text-center">
            <h6>
              Your current{" "}
              <span className="font-semibold text-teal-800">
                {cur_plan_name}
              </span>{" "}
              subscription is waiting for approval from Razorpay. Please wait...
            </h6>
            <div className="flex justify-center">
              <FaSpinner className="text-teal-800 animate-spin" size="24" />
            </div>
          </div>
        ) : null}

        {/* Plans */}
        <div className="flex flex-wrap justify-center gap-4 mt-6">
          {plans?.map((plan, index) => (
            <div
              key={index}
              className="relative z-10 flex flex-col px-6 py-4 bg-white shadow-lg w-fit rounded-2xl"
            >
              <div className="flex items-center gap-2 pb-2">
                <img
                  src={R}
                  className="w-8 h-8"
                  alt="R"
                />
                <p className="text-base font-semibold">{plan.plan_name} Plan</p>
              </div>
              <hr />
              <div className="flex-auto">
                <ul className="pt-2 pl-0 ml-5 text-sm text-gray-800">
                  {plan?.plan_description.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
              <div className="flex-none">
                <hr className="my-2" />
                <div className="flex items-center justify-between">
                  <p className="text-xl font-semibold">
                    {DEFAULT_CURRENCY} {(plan.plan_price / CURRENCY_CONVERSION).toFixed(2)}
                    <span className="text-lg">/{plan.plan_name?.replace("PRO ", "").replace("LY", "").toLowerCase()}
                    </span>
                  </p>
                  <button
                    className="p-2 text-xs font-semibold text-white bg-orange-500 rounded-lg"
                    onClick={() => {
                      if (["active"].includes(currentAtivePlanStatus)) {
                        toast.error(
                          "Your current plan is still active. Please wait for it to expire or cancel it before adding a new plan.",
                          { autoClose: 4000 }
                        );
                        return;
                      }
                      navigate(`/checkout?plan_id=${plan?.rp_plan_id}`);
                    }}
                  >
                    UPGRADE
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center justify-center w-full px-4 py-1 mt-10 space-y-10 md:w-8/12">
          {/* Payment History and Download header */}
          <div className="flex items-center justify-between w-full">
            <p className="text-lg font-bold">Payment History</p>
            <button
              onClick={downloadInvoice}
              className="flex flex-wrap items-center gap-1 px-2 py-1 text-base font-semibold border-gray-900 rounded-lg border-1"
              disable={false}
            >
              <img src={download} alt="download" />
              Download
            </button>
          </div>

          {/* Invoices */}
          {/* <div> */}
          <div className="flex items-center justify-center w-full">
            <table className="min-w-full overflow-hidden bg-white rounded-lg shadow-md">
              <thead className="bg-gray-200">
                <tr>
                  <th className="w-10 px-4 py-1 text-left">
                    <input
                      type="checkbox"
                      className="w-4 h-4 form-checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>

                  <th className="flex flex-wrap items-center px-4 py-2 text-left">
                    <svg
                      fill="currentColor"
                      strokeWidth="0"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                      height="1em"
                      width="1em"
                    >
                      <path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0 0 48.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path>
                    </svg>
                    Invoice
                  </th>
                  <th className="w-24 px-2 py-1 text-left">Amount</th>
                  <th className="w-32 px-2 py-1 text-left">Date</th>
                  <th className="w-24 px-2 py-1 text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionDetails.map((subscription, index) => (
                  <tr className="border-t" key={index}>
                    <td className="px-4 py-2 text-gray-500">
                      <input
                        type="checkbox"
                        className="w-4 h-4 form-checkbox"
                        checked={selectedInvoice.includes(
                          subscription?.invoiceURL
                        )}
                        onChange={() =>
                          handleCheckboxChanges(subscription.invoiceURL)
                        }
                      />
                    </td>
                    <td className="px-4 py-2 text-sm font-semibold">
                      {subscription.productName}
                    </td>
                    <td className="px-2 py-2 text-sm font-semibold text-gray-500">
                    {DEFAULT_CURRENCY} {Math.round(subscription.amount / CURRENCY_CONVERSION)}
                    </td>
                    <td className="px-2 py-2 text-sm font-semibold text-gray-500">
                      {subscription.subscriptionChargedDate}
                    </td>
                    <td className="px-2 py-2">
                      <span className="flex flex-wrap items-center gap-1 py-1 pl-2 pr-3 text-xs font-semibold text-green-800 bg-green-200 rounded-full w-fit">
                        <svg
                          fill="currentColor"
                          strokeWidth="0"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                        >
                          <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7l233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z"></path>
                        </svg>
                        Paid
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* </div> */}
        </div>
      </div>

      {["active"].includes(currentAtivePlanStatus) &&
        !["completed"].includes(currentAtivePlanStatus) && (
          <p className="text-sm text-center">
            Do you want to cancel current active plan?{" "}
            <a onClick={handleDpop} className="underline cursor-pointer">
              Deactivate
            </a>
          </p>
          // <>
          //   {cancelSubscriptionLoader ? (
          //     <FaSpinner className="text-white animate-spin" size="28" />
          //   ) : (
          //     <p className="text-sm text-center">
          //       Do you want to cancel current active plan?{" "}
          //       <a onClick={handleDpop} className="underline cursor-pointer">
          //         Deactivate
          //       </a>
          //     </p>
          //   )}
          // </>
        )}
      <Footer />
    </div>
  );
}

export default Payments;

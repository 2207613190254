// import Book1Img from '../image/crown.png';
import { useEffect } from "react";

// export default function Checkout(){

//     const loadScript = (src) => {
//         return new Promise((resolve) => {
//           const script = document.createElement("script");
//           script.src = src;
//           script.onload = () => {
//             resolve(true);
//           };
//           script.onerror = () => {
//             resolve(false);
//           };
//         document.body.appendChild(script);
//       });
//     };

//     useEffect(() => {
//         loadScript("https://checkout.razorpay.com/v1/checkout.js");
//     });

//     function makePayment(e, price, product_name, plan_id){

//         e.preventDefault();

//         let formData = new FormData();
//         formData.append('price', price);
//         formData.append('product_name', product_name);
//         formData.append('plan_id', plan_id);

//         async function paymentGateway() {
//             const authToken = localStorage.getItem('access');
//             const url = 'https://resumeapi.hirebeen.com/new-subscription' // <<< new-subscription backend api url path
//             const res = await fetch(url, { method: 'POST',
//                 headers: {
//                     'Authorization': `Bearer ${authToken}`
//                   },
//                 body: formData ,
//             })
//             const jsonRes = await res.json()
//             return jsonRes
//         }

//         paymentGateway().then((res) =>{
//             //_________ call razorpay gateway ________
//             var options = {
//                 "key": res['razorpay_key'],
//                 "amount": res['order']['amount'],
//                 "currency": res['order']['currency'],
//                 "callback_url": res['callback_url'],
//                 prefill: {
//                     "email": "nosob88243@xitudy.com",
//                     "contact": "1234567890"
//                 },
//                 "name": res['product_name'],
//                 "subscription_id": res['order']['id'],
//             };

//             var rzp1 = new window.Razorpay(options);
//             rzp1.open();
//         })

//     }

//     return(
//         <>
//             <section className='flex justify-center mt-52'>
//                 <div className='px-10 py-5 text-center rounded drop-shadow-xl bg-zinc-50'>
//                     <h2 className='mb-10 text-3xl'>Monthly Subscription</h2>
//                     <img className='w-48 mx-auto' src={Book1Img} alt="" />
//                     <p className='mt-5 text-lg'>Price : ${DEFAULT_CURRENCY}  599</p>
//                     <button type='button' className="mt-2 mb-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-lg font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                      onClick={e=>{makePayment(e, 1, "Pro Monthly", "plan_ON2Oqk9C1oFWlq")}}
//                      >Proceed to Buy</button>
//                 </div>
//             </section>
//         </>
//     )
// }

import * as React from "react";
import RazorpayLogo from "../image/razorpay.png";
import SecureLogo from "../image/secure_pay.png";
import SuccessImg from "../image/success.png";
import { API_URL, DEFAULT_CURRENCY, CURRENCY_CONVERSION, GST_CHARGE } from "../apiConfig";
import { useSearchParams, useNavigate } from "react-router-dom";
import userStore from "../store/user.zustand";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import resumeBeen from '../image/resumebeen.svg';
import R from '../image/favicons/R.svg'
import { Helmet } from "react-helmet";

type StarIconProps = {
  className?: string,
};

const StarIcon: React.FC<StarIconProps> = ({ className }) => (
  <img
    loading="lazy"
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/43f573f5d9db6e1353696755d708dfd08de049fa862bd1d5e86e7456ae1faf92?apiKey=44137d5b582349c982f95e568cecc6d3&"
    className={`w-5 aspect-square ${className}`}
    alt=""
  />
);

type FeatureItemProps = {
  text: string,
};

const FeatureItem: React.FC<FeatureItemProps> = ({ text }) => (
  <div className="flex gap-1 mt-1">
    <StarIcon />
    <div className="flex-auto text-lg text-black">{text}</div>
  </div>
);

type BillingDetailProps = {
  label: string,
  value: string,
};

const BillingDetail: React.FC<BillingDetailProps> = ({ label, value }) => (
  <>
    <div className="mt-1 text-lg">{label}</div>
    <div className="mt-[-3px] text-lg text-black">{value}</div>
  </>
);

const Checkout = () => {
  const [userData, setUserData] = React.useState(null);
  const [planData, setPlanData] = React.useState(null);
  const [showPopup, setShowPopup] = React.useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const subscriptionId = searchParams.get("subscription_id");
  const status = searchParams.get("status");
  const planId = searchParams.get("plan_id");
  const amount = searchParams.get("amount");
  const plan_name = searchParams.get("plan_name");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [subId, setSubId] = useState("")
  const billingDetails = [
    { label: "Name", value: "Dheeraj Srivastava" },
    { label: "Phone", value: "9500005281" },
    { label: "Email", value: "support@techbeen.co" },
  ];

  const { setUser, setAuthenticated } = userStore();

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  React.useEffect(() => {
    if (status) {
      setShowPopup(true);
    }
  }, [status]);

  async function makePayment(e, price, product_name, plan_id, plan_duration) {
    e.preventDefault();

    let formData = new FormData();
    formData.append("price", price);
    formData.append("product_name", product_name);
    formData.append("plan_id", plan_id);
    formData.append("plan_duration", plan_duration);
    console.log(price, product_name, plan_id, plan_duration);
    console.log(formData);

    // call api ${DEFAULT_CURRENCY}
    const response = await axios.post(
      `${API_URL}/api/v1/payment/create-subscription`,
      {
        amount: price,
        currency: `${DEFAULT_CURRENCY}`,
        receipt: plan_id,
        // product_name,
        // plan_duration,
      },
      { withCredentials: true }
    );

    var options = {
      key: "rzp_test_7vUQoFd1sU9ZA3", // Enter the Key ID generated from the Dashboard
      amount, // Amount is in currency subunits. Default currency is ${DEFAULT_CURRENCY}. Hence, 50000 refers to 50000 paise
      currency: `${DEFAULT_CURRENCY}`,
      name: "Hirebeen", //your business name
      description: "Test Transaction",
      // image: "https://example.com/your_logo",
      order_id: response.data.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          ...response,
        };

        const validateResponse = await axios.post(
          `${API_URL}/api/v1/payment/callback`,
          JSON.stringify(body),
          { withCredentials: true }
        );

        // const validateRes = await fetch(
        //   "http://localhost:5000/order/validate",
        //   {
        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //   }
        // );
        const jsonRes = await validateResponse.json();
        console.log(jsonRes);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: userData?.email, //your customer's name
        email: userData?.firstName,
        contact: userData?.phone, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();

    // async function paymentGateway() {
    //   const authToken = localStorage.getItem("access");
    //   const url = "https://resumeapi.hirebeen.com/new-subscription"; // <<< new-subscription backend api url path
    //   const res = await fetch(url, {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${authToken}`,
    //     },
    //     body: formData,
    //   });
    //   const jsonRes = await res.json();
    //   return jsonRes;
    // }

    // paymentGateway().then((res) => {
    //   //_________ call razorpay gateway ________
    //   console.log(res)
    //   var options = {
    //     key: res["razorpay_key"],
    //     amount: res["order"]["amount"],
    //     currency: res["order"]["currency"],
    //     callback_url: res["callback_url"],
    //     prefill: {
    //       email: userData.email,
    //       contact: userData.phone,
    //     },
    //     name: res["product_name"],
    //     subscription_id: res["order"]["id"],
    //   };

    //   var rzp1 = new window.Razorpay(options);
    //   rzp1.open();
    // });
  }

  const handlePaymentSuccess = async (response) => {
    try {
      const {
        razorpay_payment_id,
        razorpay_subscription_id,
        razorpay_signature,
      } = response;

      if (
        !razorpay_payment_id ||
        !razorpay_subscription_id ||
        !razorpay_signature
      ) {
        toast.error("Internal server error", {
          autoClose: 1500,
        });
        return;
      }

      const verificationResponse = await axios.post(
        `${API_URL}/api/v1/payment/verify-payment`,
        {
          razorpay_payment_id,
          razorpay_subscription_id,
          razorpay_signature,
        }
      );

      const redirectUri = verificationResponse?.data?.redirectUri;

      // Debugging logs
      //console.log('verification (stringified):', JSON.stringify(verificationResponse));
      //console.log('data URI (stringified):', JSON.stringify(verificationResponse?.data));
      //alert(`URItest: ${redirectUri}`);
    
      // Check if the payment was successful
      if (verificationResponse?.data?.success) {
        const thankYouUri = `/thank-you?plan_id=${verificationResponse.data.subscription.plan_id}&status=success&subscription_id=${verificationResponse.data.subscription.subscriptionId}&amount=${verificationResponse.data.subscription.price}&plan_name=${encodeURIComponent(verificationResponse.data.subscription.product_name)}`;
        console.log('Redirecting to:', thankYouUri);
        window.location.href = thankYouUri;
      } else {
        console.error("Payment verification failed:", verificationResponse?.data?.message);
    
        // Redirect to failure page or handle error
        window.location.href = "/payment-failed";
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
    }
  };

  const handleRazorpayClose = async (subid) => {
    setPaymentLoader(false);

    // const {
    //   razorpay_subscription_id,
    // } = response;

    if (!subid) {
      console.error("No subscription ID provided for abandon");
      setPaymentLoader(false);
      return;
    }

    const resp = await axios.post(
      `${API_URL}/api/v1/payment/abandon`,
      {
        subscriptionId: subid,
      },
      { withCredentials: true }
    );


    if (!resp?.data?.success) {
      console.error("Error abandoning subscription:", resp.data.message);
      setPaymentLoader(false);
      toast.error(resp?.data?.message, { autoClose: 3500 })
      return;
    }

    navigate("/payments")
    toast.error(resp?.data?.message, {
      autoClose: 3000,
    });

  };

  const handleCreateSubscription = async (planAmount) => {
    try {
      if (!planId) {
        toast.error("Please select the plan", {
          autoClose: 2500,
        });

        return;
      }
      setPaymentLoader(true);
      // return
      const response = await axios.post(
        `${API_URL}/api/v1/payment/create-subscription`,
        {
          razorpayPlanId: planId,
        }
      );

      const subscriptionData = response.data.data;
      setSubId(subscriptionData?.subscription?.id)


      if (!response.data.success) {
        console.error("Error creating subscription:", response.data.message);
        setPaymentLoader(false); // Deactivate the loader if an error occurs
        return;
      }

      // Proceed to payment using Razorpay
      const options = {
        key: subscriptionData?.options?.key,
        subscription_id: subscriptionData?.subscription?.id,
        name: "Hirebeen",
        currency: subscriptionData?.options?.currency,
        amount: planAmount,
        description: subscriptionData?.options?.description,
        handler: async (response) => {
          await handlePaymentSuccess(response);
        },
        prefill: {
          name: userData?.fulllName,
          email: userData?.email,
          contact: userData?.phone,
        },
        theme: {
          color: "#1d6362",
        },
        modal: {
          ondismiss: async () => {
            await handleRazorpayClose(subscriptionData?.subscription?.id)
          }
        },
      };

      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
      setPaymentLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 3000 });
      console.error("Error creating subscription:", error);
      setPaymentLoader(false);
      return;
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/users/me`, {
        withCredentials: true,
      });

      if (response?.data?.success) {
        setUserData(response?.data?.data);
        setUser(response?.data?.data)
        setAuthenticated(true)
        // userStore
        //   .getState()
        //   .setUser({ user: response?.data?.data, isAuthenticated: true });
      } else {
        if (
          response?.data?.message === "Unauthorized" ||
          response?.data?.message === "User not found"
        ) {
          // redirect to login
          navigate("/login", { replace: true });
        }
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      }
      return;
    } catch (error) {
      setAuthenticated(false)
      // userStore.getState().setUser({ isAuthenticated: false });
      toast.error(error?.response?.data?.message, {
        autoClose: 2500,
      });
      navigate("/login", { replace: true });
      console.log(error);
      return;
    }
  };

  const fetchSelectedPlan = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/plan/`, {
        params: {
          razorpayId: planId,
        },
        withCredentials: true,
      });
      // Need to create zustand store for plans
      if (response?.data?.success) {
        setPlanData(response?.data?.data);
      } else {
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };

  // useEffect(() => {
  //   // Fetch user and plan details from your API
  //   const fetchCheckoutData = async () => {
  //     const authToken = localStorage.getItem("access");
  //     const url = `${API_URL}/user-checkout/?plan_id=` + planId; // Replace with your actual API endpoint
  //     const response = await fetch(url, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       setUserData(data.user);
  //       setPlanData(data.plan);
  //     } else {
  //       console.error("Failed to fetch user and plan details");
  //     }
  //   };

  //   fetchCheckoutData();
  // }, []);

  useEffect(() => {
    fetchUser();
    fetchSelectedPlan();
  }, []);

  const billerDetails = userData
    ? [
      { label: "Name", value: userData?.firstName },
      { label: "Phone", value: userData.phone },
      { label: "Email", value: userData.email },
    ]
    : [];

  const getSubscriptionText = (planType, planPrice) => {
    switch (planType) {
      case "PRO MONTHLY":
        return `You consent to be billed and start your PRO MONTHLY subscription today. If you do not cancel before the end of the subscription period, you will automatically be charged the subscription fee of ${DEFAULT_CURRENCY} ${planPrice}, billed every month until you cancel.`;
      case "PRO QUARTERLY":
        return `You consent to be billed and start your PRO QUARTERLY subscription today. If you do not cancel before the end of the subscription period, you will automatically be charged the subscription fee of ${DEFAULT_CURRENCY}  ${planPrice} , billed every 3 months until you cancel.`;
      case "PRO YEARLY":
        return `You consent to be billed and start your PRO YEARLY subscription today. If you do not cancel before the end of the subscription period, you will automatically be charged the subscription fee of ${DEFAULT_CURRENCY}  ${planPrice} , billed every year until you cancel.`;
      default:
        return "";
    }
  };

  const getChargeText = (planType, planPrice) => {
    switch (planType) {
      case "PRO MONTHLY":
        return `Further, ${DEFAULT_CURRENCY}  ${(planPrice / CURRENCY_CONVERSION).toFixed(2)}  will be charged every month.`;
      case "PRO QUARTERLY":
        return `Further, ${DEFAULT_CURRENCY}  ${(planPrice / CURRENCY_CONVERSION).toFixed(2)}  will be charged every 3 months.`;
      case "PRO YEARLY":
        return `Further, ${DEFAULT_CURRENCY}  ${(planPrice / CURRENCY_CONVERSION).toFixed(2)}  will be charged every year.`;
      default:
        return "";
    }
  };

  return (
    <main className="pl-8 bg-white max-md:pl-5">
      {showPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
          <div className="p-6 text-center bg-white rounded-lg shadow-lg">
            {status === "success" ? (
              <div>
                <div className="relative flex items-center justify-center w-24 h-24 mx-auto mb-4 rounded-full">
                  <img src={SuccessImg} alt="" />
                </div>
                {/* <h1 className="mb-2 text-2xl font-semibold"></h1> */}
                <h2 className="mb-2 text-xl">Thank you Payment Complete</h2>
                <p>Transaction ID: {subscriptionId}</p>
                <div className="bg-[#f8f6f6] p-2 px-4 my-4 text-left">
                  <p className="text-sm">Plan : {plan_name}</p>
                  <p className="text-sm">Amount : ${DEFAULT_CURRENCY}  ${(amount / CURRENCY_CONVERSION).toFixed(2)} </p>
                </div>
                <Helmet>
                  <script>
                    {`
                      gtag('event', 'conversion', {
                        'send_to': 'AW-943384411/hScLCIvzo-0ZENvO68ED',
                        'value': ${(amount / CURRENCY_CONVERSION).toFixed(2)}, 
                        'currency': ${DEFAULT_CURRENCY},
                        'transaction_id': '${subscriptionId}'
                      });
                    `}
                  </script>
                </Helmet>
                <button
                  className="px-4 py-2 text-white bg-teal-800 rounded-lg"
                  onClick={() => navigate("/payments")}
                >
                  Go to Payments
                </button>
              </div>
            ) : (
              <div>
                <h2 className="mb-4 text-xl">
                  There was an issue with your payment
                </h2>
                <button
                  className="px-4 py-2 text-white bg-gray-800 rounded-lg"
                  onClick={() => setShowPopup(null)}
                >
                  Try Again
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        {planData && (
          <section className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col mt-6 max-md:mt-10 max-md:max-w-full">
              <div className="max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                  <div className="flex flex-col w-[69%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                      <img
                        loading="lazy"
                        src={resumeBeen}
                        className="max-w-full aspect-[4.35] w-[180px]"
                        alt="Company logo"
                        onClick={() => navigate("/")}
                      />
                      <div className="flex flex-col pl-8 mt-8 max-md:pl-5 max-md:max-w-full">
                        <div className="flex gap-4 text-3xl font-semibold text-black">
                          <img
                            loading="lazy"
                            src={R}
                            className="w-10 shrink-0 aspect-square"
                            alt="R"
                          />
                          <h1 className="flex-auto my-auto">
                            {planData.plan_name} PLAN
                          </h1>
                        </div>
                        <div className="flex gap-1 mt-2 ml-16">
                          {/* <div className="flex flex-col items-center max-md:hidden">
                          {[...Array(8)].map((_, index) => (
                            <StarIcon key={index} className={index > 0 ? "mt-3.5" : ""} />
                          ))}
                        </div> */}
                          <div className="flex-auto">
                            {planData?.plan_description.map(
                              (feature, index) => (
                                <FeatureItem key={index} text={feature} />
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col ml-5 w-[31%] max-md:ml-0 max-md:w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2752a02f081ccbad0485fab4d432fab3c65f969416c07d57400bb635697e1716?apiKey=44137d5b582349c982f95e568cecc6d3&"
                      className="shrink-0 mt-28 max-w-full aspect-[0.83] w-[170px] max-md:mt-10"
                      alt="Decorative"
                    />
                  </div>
                </div>
              </div>
              <footer className="flex flex-col px-8 mt-12 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <hr className="shrink-0 max-w-full h-0.5 border-2 border-solid bg-neutral-200 border-neutral-200 w-[620px]" />
                <div className="flex gap-3 mt-7 mr-5 max-md:flex-wrap max-md:mr-2.5">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/dd666a2442c34c3084f20ab0874c7d9883fe3a9a049115af9635ea74569012c3?apiKey=44137d5b582349c982f95e568cecc6d3&"
                    className="shrink-0 my-auto max-w-full aspect-[2.5] w-[100px]"
                    alt="Payment method logo"
                  />
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4b23531e0b0627ed81592122002cc948d73bfc38a675b99bb501905f6227e9d9?apiKey=44137d5b582349c982f95e568cecc6d3&"
                    className="grow shrink-0 aspect-[7.14] basis-0 w-[80px]"
                    alt="Payment security badges"
                  />
                </div>
                {/* <p className="ml-4 mt-11 text-md text-neutral-500 max-md:mt-10 max-md:max-w-full">
                  You consent to be billed and start your {planData?.plan_name}{" "}
                  subscription today. If you do not cancel before the end of the
                  subscription period, you will automatically be charged the
                  subscription fee of ${DEFAULT_CURRENCY} {planData?.plan_price}, billed every month
                  until you cancel.
                </p> */}
                <p className="ml-4 mt-11 text-md text-neutral-500 max-md:mt-10 max-md:max-w-full">
                  {getSubscriptionText(
                    planData?.plan_name,
                    (planData?.plan_price / CURRENCY_CONVERSION).toFixed(2)
                    // planData?.plan_price
                  )}
                </p>
                <p className="self-start mt-28 ml-4 text-lg text-neutral-500 max-md:mt-10 max-md:ml-2.5">
                  ©2024 ResumeBeen. All rights reserved
                </p>
              </footer>
            </div>
          </section>
        )}
        {userData && (
          <section className="flex flex-col w-6/12 ml-9 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col w-full py-16 pl-12 pr-16 font-semibold grow bg-zinc-100 max-md:px-5 max-md:mt-10 max-md:max-w-full">
              <div className="flex self-start justify-between gap-3">
                <div className="flex flex-col text-2xl text-white whitespace-nowrap">
                  <div className="justify-center items-center text-sm px-[10px] py-1 w-fit bg-orange-500 rounded-full">
                    1
                  </div>
                  <div className="shrink-0 self-center mt-3 w-[2px] bg-black border border-black border-solid h-[200px]"></div>
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl mt-[-3px] text-black">
                    Billing Details
                  </h2>
                  <div className="flex flex-col pl-4 mt-3 text-xl text-neutral-400">
                    {billerDetails.map((detail, index) => (
                      <BillingDetail
                        key={index}
                        label={detail.label}
                        value={detail.value}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex self-start gap-3 mt-3">
                <div className="flex flex-col text-white whitespace-nowrap">
                  <div className="justify-center items-center text-sm px-[10px] mb-1 py-1 w-fit bg-zinc-900 rounded-full">
                    2
                  </div>
                </div>
                <h2 className="text-2xl mt-[-3px] text-black">
                  Payment Summary
                </h2>
              </div>
              <div
                // className="svg-container"
                className="svg-container flex ml-[12px] w-[250px] h-[350px]"
              >
                <svg
                  width="250"
                  height="250"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 10 V160 C0 220 60 220 60 220 H150"
                    stroke="grey"
                    stroke-width="2"
                    fill="none"
                  />
                </svg>

                <div className="absolute z-10 mx-10 mt-2 w-[400px] h-fit">
                  <div className="flex flex-col gap-2 bg-white mt-2 rounded-xl px-4 py-3 w-[400px] h-fit">
                    <div className="flex justify-between w-full text-2xl text-black">
                      <p className="text-base text-gray-500">
                        {planData?.plan_name}
                      </p>
                      <p className="text-base text-gray-500">
                        {planData?.plan_duration} x {DEFAULT_CURRENCY}{" "}
                        {/* Math.round((plan.plan_price - plan.plan_sale) / CURRENCY_CONVERSION) */}
                        {((planData?.plan_price) / CURRENCY_CONVERSION).toFixed(2) -
                          ((
                            planData?.plan_price -
                            planData?.plan_price / (1 + (GST_CHARGE/100))
                          ).toFixed(2) / CURRENCY_CONVERSION).toFixed(2)}
                      </p>
                    </div>
                    <div className="flex justify-between w-full text-2xl text-black">
                      <p className="text-base text-gray-500">Tax</p>
                      <p className="text-base text-gray-500">
                        {DEFAULT_CURRENCY}{" "}
                        {((
                          planData?.plan_price -
                          planData?.plan_price / (1 + (GST_CHARGE/100))
                        ) / CURRENCY_CONVERSION).toFixed(2)}
                      </p>
                    </div>
                    <hr className="h-[2px]" />
                    <div className="flex justify-between w-full text-2xl text-black">
                      <p className="text-base text-gray-500">
                        Total Billed Now
                      </p>
                      <p className="text-base text-gray-500">
                        {DEFAULT_CURRENCY} {(planData?.plan_price / CURRENCY_CONVERSION).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  {/* <p className="mt-1 ml-1 text-sm text-gray-500">
                    *Further, ${DEFAULT_CURRENCY} {planData?.plan_price} will be charged every
                    month
                  </p> */}
                  <p className="mt-1 ml-1 text-sm text-gray-500">
                    {getChargeText(planData?.plan_name, planData?.plan_price)}
                  </p>
                  <div className="flex justify-center w-full">
                    <button
                      className="flex justify-center items-center px-16 py-2.5 mt-6 text-xl w-[370px] text-white bg-teal-800 rounded-2xl"
                      onClick={(e) => {
                        handleCreateSubscription(planData?.plan_price);
                      }}
                      disabled={paymentLoader}
                    >
                      {paymentLoader ? (
                        <FaSpinner
                          className="text-white animate-spin"
                          size="28"
                        />
                      ) : (
                        "Pay and Upgrade"
                      )}
                    </button>
                  </div>
                  <div className="flex justify-center gap-4 mt-3 text-base text-black">
                    <img className="w-24" src={SecureLogo} alt="" />
                    <img className="w-24" src={resumeBeen} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default Checkout;

// import React, { useState } from 'react';
// import axios from 'axios';
// import { API_URL } from '../apiConfig';

// const Checkout = () => {
//     const [selectedPlan, setSelectedPlan] = useState(null);

//     // Dummy plan for testing
//     const dummyPlan = {
//         id: 'plan_ON2Oqk9C1oFWlq',
//         name: 'Pro Monthly',
//         price: 599,
//     };

//     const handleSubscribe = async () => {
//         if (!selectedPlan) return;

//         try {
//             const response = await axios.post(`http://127.0.0.1:8000/new-subscription`, {
//                 plan_id: selectedPlan.id,
//                 price: selectedPlan.price,
//                 product_name: selectedPlan.name,
//             });

//             const { order, razorpay_key, callback_url } = response.data;

//             const options = {
//                 key: razorpay_key,
//                 subscription_id: order.id,
//                 name: selectedPlan.name,
//                 handler: function (response) {
//                     axios.post(callback_url, response)
//                         .then(res => {
//                             console.log(res.data);
//                         })
//                         .catch(err => {
//                             console.error(err);
//                         });
//                 },
//                 prefill: {
//                     name: '',
//                     email: '',
//                     contact: '',
//                 },
//                 notes: {
//                     address: '',
//                 },
//                 theme: {
//                     color: '#F37254',
//                 },
//             };

//             const razorpay = new window.Razorpay(options);
//             razorpay.open();
//         } catch (error) {
//             console.error('Error initiating subscription:', error);
//         }
//     };

//     return (
//         <div>
//             <h2>Choose a Plan</h2>
//             <ul>
//                 <li key={dummyPlan.id}>
//                     <h3>{dummyPlan.name}</h3>
//                     <p>Price: {dummyPlan.price}</p>
//                     <button onClick={() => setSelectedPlan(dummyPlan)}>Select</button>
//                 </li>
//             </ul>
//             <button onClick={handleSubscribe}>Checkout</button>
//         </div>
//     );
// };

// export default Checkout;
